import * as React from "react";

const StickIconComponent = ({ width, height, props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#2bb1bb" />
        <stop offset={1} stopColor="#51dfd2" />
      </linearGradient>
    </defs>
    <g data-name="Group 31303">
      <path
        data-name="Path 24378"
        d="M40.852 0h6.927a1.853 1.853 0 0 0 .445.166 42.46 42.46 0 0 1 18.745 6.078C78.8 13.526 85.9 24.031 88.153 37.766c.171 1.035.332 2.071.5 3.106V47.8a4.438 4.438 0 0 0-.178.623c-.253 1.651-.405 3.326-.753 4.956a44.222 44.222 0 0 1-37.038 34.809 42.793 42.793 0 0 1-25.989-4.108A44.325 44.325 0 0 1 37.746.494C38.784.345 39.817.164 40.852 0m-2.047 56.044c-.263-.19-.474-.332-.673-.49q-5.746-4.591-11.485-9.187c-1.419-1.135-2.833-2.274-4.264-3.395a1.7 1.7 0 0 0-2.094-.1 1.565 1.565 0 0 0-.711 1.881 2.822 2.822 0 0 0 .9 1.208c2.522 2.056 5.075 4.082 7.622 6.117l9.391 7.512c1.3 1.038 2.047.969 3.182-.282l.291-.32q12.445-14 24.892-28.007c.959-1.078 1.938-2.137 2.859-3.246a1.76 1.76 0 0 0-2.628-2.335c-.31.3-.588.637-.874.962L39.573 55.2c-.244.275-.5.543-.768.841"
        transform="translate(0 -.001)"
        fill="url(#a)"
      />
      <path
        data-name="Path 24379"
        d="M38.805 56.048c.272-.3.524-.566.768-.841l25.64-28.851c.287-.325.564-.659.874-.962a1.76 1.76 0 0 1 2.627 2.341c-.922 1.109-1.9 2.168-2.859 3.246Q53.411 44.985 40.963 58.988l-.291.32c-1.135 1.251-1.883 1.32-3.182.282q-4.7-3.756-9.391-7.512c-2.547-2.035-5.1-4.061-7.624-6.122a2.822 2.822 0 0 1-.9-1.208 1.565 1.565 0 0 1 .711-1.881 1.7 1.7 0 0 1 2.094.1c1.431 1.121 2.845 2.26 4.264 3.395l11.485 9.187c.2.159.41.3.673.49"
        fill="#fcfdfd"
      />
    </g>
  </svg>
);

StickIconComponent.defaultProps = {
  width: "88.65",
  height: "88.698",
};

export default StickIconComponent;
