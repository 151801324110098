import * as React from "react";

const CheckIcon = ({ width, height, props }: any) => (
  <svg
    data-name="Group 30577"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <path
      data-name="Path 21877"
      d="M7.437 13.997h-.875c-.171-.021-.342-.039-.512-.064a6.889 6.889 0 0 1-3.676-1.692A6.889 6.889 0 0 1 .109 8.217C.062 7.958.036 7.696 0 7.435V6.56c.021-.171.039-.343.063-.513A6.869 6.869 0 0 1 2 2.111 6.85 6.85 0 0 1 6.83.003a6.567 6.567 0 0 1 3.7.961 6.861 6.861 0 0 1 3.463 5.917A6.533 6.533 0 0 1 13 10.587a6.908 6.908 0 0 1-4.8 3.3c-.248.05-.507.074-.763.11ZM6.354 8.106c-.053-.068-.07-.1-.093-.118-.457-.458-.916-.914-1.371-1.372a.581.581 0 0 0-.609-.173.579.579 0 0 0-.226.986q.9.907 1.806 1.807a.6.6 0 0 0 .969 0l3.642-3.642a1.6 1.6 0 0 0 .113-.119.579.579 0 0 0-.349-.941.593.593 0 0 0-.552.2q-1.607 1.61-3.216 3.218a1.369 1.369 0 0 0-.114.153Z"
      fill="#36c277"
    />
    <path
      data-name="Path 21878"
      d="M6.353 8.105a1.37 1.37 0 0 1 .114-.158q1.607-1.61 3.216-3.218a.593.593 0 0 1 .552-.2.579.579 0 0 1 .349.941 1.6 1.6 0 0 1-.113.119L6.829 9.231a.6.6 0 0 1-.969 0L4.054 7.424a.584.584 0 1 1 .836-.813c.456.459.914.915 1.371 1.372.022.022.039.055.092.122Z"
      fill="#fff"
    />
  </svg>
);

CheckIcon.defaultProps = {
  width: "13.997",
  height: "13.997",
};

export default CheckIcon;
