import * as React from "react"

const FooterinstaIcon = ({width, height, props}:any) => (

    <svg id="socialInstagram" xmlns="http://www.w3.org/2000/svg"width={width} height={height} {...props}>
        <defs>
        </defs>
        <path id="Path_149" data-name="Path 149" d="M17.072 1.2H7.191A6.1 6.1 0 0 0 1.1 7.291v9.881a6.1 6.1 0 0 0 6.091 6.091h9.881a6.1 6.1 0 0 0 6.091-6.091v-9.9A6.119 6.119 0 0 0 17.072 1.2zM21.2 17.15a4.13 4.13 0 0 1-4.128 4.128H7.191a4.13 4.13 0 0 1-4.128-4.128V7.269A4.13 4.13 0 0 1 7.191 3.14h9.881A4.13 4.13 0 0 1 21.2 7.269v9.881z" transform="translate(-1.1 -1.2)"/>
        <path id="Path_150" data-name="Path 150" d="M30.485 24.8a5.685 5.685 0 1 0 5.685 5.685 5.69 5.69 0 0 0-5.685-5.685zm0 9.407a3.722 3.722 0 1 1 3.722-3.722 3.726 3.726 0 0 1-3.722 3.722z" transform="translate(-19.453 -19.476)"/>
        <path id="Path_151" data-name="Path 151" d="M71.244 17.5a1.464 1.464 0 1 0 1.015.429 1.435 1.435 0 0 0-1.015-.429z" transform="translate(-54.301 -13.823)"/>
    </svg>

)

FooterinstaIcon.defaultProps = {
    width: '22.063',
    height: '22.063'
}

export default FooterinstaIcon