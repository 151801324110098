import * as React from "react"

const SquirilRecruiterIcon = ({ width, height, props }: any) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 89.3 29"
        style={{
            enableBackground: "new 0 0 89.3 29",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{".sr1{fill:#fff}.sr3{fill:#fff}.sr4{fill:#787fe4}"}</style>
        <path
            d="m36.7 15-.2.1"
            style={{
                fill: "#7052cc",
            }}
        />
        <path
            className="sr1"
            d="M37.9 15.2c-1.3 0-2.6-.3-3.8-1l.5-1.7c.5.3 1.1.5 1.6.7.6.2 1.1.3 1.7.3.6 0 1.1-.1 1.5-.4.4-.3.6-.8.5-1.3 0-.7-.5-1.2-1.5-1.5-.1 0-.3-.1-.6-.2l-.9-.3c-.3-.1-.5-.2-.6-.2-1.4-.3-2.4-1.6-2.4-3.1 0-1 .4-1.9 1.1-2.4.9-.6 1.9-.9 3-.9 1.3 0 2.5.3 3.6.9l-.7 1.6C40 5.3 39 5 38 5c-.5 0-1 .1-1.4.3-.3.3-.5.7-.5 1.1 0 .6.4 1.1 1 1.3.1.1.4.1.9.3s.8.3 1 .3c.9.2 1.7.7 2.3 1.3.5.6.7 1.4.7 2.2.1 1-.4 2-1.2 2.6-.6.5-1.8.8-2.9.8zM49.7 18.6v-3.7c0-.4 0-.8.1-1.1-.5.9-1.5 1.4-2.5 1.4-1.1 0-2.1-.4-2.7-1.3-.7-1-1.1-2.1-1-3.3-.1-1.2.3-2.3 1-3.3.5-.8 1.6-1.3 2.6-1.3 1-.1 2 .5 2.6 1.3l.2-1h1.8v12.3h-2.1zm-2-5c.6 0 1.2-.2 1.6-.7.4-.6.6-1.4.6-2.2.1-.8-.1-1.7-.5-2.4-.4-.5-1-.8-1.6-.7-.6 0-1.2.3-1.5.7-.7 1.4-.7 3.1 0 4.5.2.5.8.8 1.4.8zM60.2 14.3c-1.7 1.1-3.8 1.1-5.5 0-.7-.6-1.1-1.5-1-2.5V6.2h2.1v5.5c0 .5.1 1 .4 1.4.4.3.8.5 1.3.4.5 0 .9-.1 1.3-.4.3-.4.5-.9.4-1.4V6.2h2.1v5.6c.1 1-.3 1.9-1.1 2.5zM63.2 5V3.2h2.1V5h-2.1zm0 10V6.3h2.1V15h-2.1zM67.5 15V6.3h1.8l.3 1.3C70 6.7 71 6.2 72 6.2h.6v1.7c-.2 0-.4 0-.7-.1-.6 0-1.3.2-1.7.6-.4.5-.6 1.1-.6 1.7V15h-2.1zM73.9 5V3.2H76V5h-2.1zm0 10V6.3H76V15h-2.1zM81.2 15.1c-.5 0-1-.1-1.5-.2-.4-.1-.7-.4-.9-.7-.2-.3-.4-.7-.5-1.1-.1-.5-.1-1.1-.1-1.6V3l2.1-.1v8.6c-.1.5 0 1.1.3 1.6.3.3.7.5 1.1.4.3 0 .7 0 1-.1l.1 1.5h-.2c-.1 0-.2.1-.4.1s-.3 0-.4.1h-.6z"
        />
        <g
            style={{
                enableBackground: "new",
            }}
        >
            <path
                className="sr3"
                d="M35.6 24.2V27h-.7v-6.9H37c.8 0 1.4.2 1.8.6s.6.8.6 1.4c0 .6-.2 1-.5 1.4-.3.4-.8.6-1.4.6l2 2.9h-.9l-1.9-2.9h-1.1zm0-3.5v2.9H37c1.1 0 1.6-.5 1.6-1.4 0-1-.5-1.4-1.6-1.4h-1.4zM43.2 27.1c-.8 0-1.4-.3-1.9-.8s-.7-1.2-.7-2.1c0-.8.2-1.5.7-2s1.1-.8 1.9-.8 1.4.2 1.9.7.7 1.1.7 1.8v.5h-4.5c0 .6.2 1.1.6 1.5s.8.5 1.3.5c.4 0 .8-.1 1.1-.3.3-.2.5-.4.6-.8h.8c-.2.5-.5.9-.9 1.2-.4.5-1 .6-1.6.6zm1.9-3.1c0-.6-.2-1.1-.5-1.4s-.9-.6-1.4-.6-1 .2-1.3.5c-.4.3-.6.8-.6 1.4h3.8zM47.3 26.4c-.5-.5-.7-1.2-.7-2.1 0-.8.2-1.5.7-2 .5-.5 1.1-.8 1.9-.8 1.3 0 2.1.6 2.4 1.7h-.8c-.1-.4-.3-.7-.6-.9-.2-.2-.5-.3-1-.3-.6 0-1 .2-1.4.6s-.5 1-.5 1.7.2 1.3.5 1.7.8.6 1.4.6c.4 0 .8-.1 1.1-.3.3-.2.5-.5.6-.9h.8c-.2.5-.5.9-.9 1.2-.4.3-.9.5-1.6.5-.7 0-1.4-.2-1.9-.7zM53.7 21.6v.9c.3-.7.9-1 1.7-1v.7h-.2c-.5 0-.8.1-1.1.4-.3.3-.4.7-.4 1.3V27H53v-5.5h.7zM60.4 21.6h.7V27h-.7v-.9c-.4.7-1 1-1.8 1-.6 0-1.1-.2-1.5-.6-.4-.4-.6-1-.6-1.7v-3.2h.7v3.1c0 .6.1 1.1.4 1.4s.7.5 1.2.5.9-.2 1.2-.5.4-.8.4-1.4v-3.1zM63.5 20c0 .1 0 .3-.1.4-.1.1-.2.1-.4.1-.1 0-.3 0-.4-.1-.1-.1-.2-.2-.2-.4s0-.3.2-.4c.1-.1.2-.1.4-.1.1 0 .3 0 .4.1 0 .2.1.3.1.4zm-.9 7v-5.5h.7V27h-.7zM67.2 22.1h-1.5v3.6c0 .3.1.5.2.6.1.1.3.2.6.2h.8v.5h-.9c-.9 0-1.3-.4-1.3-1.3v-3.6h-.8v-.5h.7v-1.4h.7v1.4h1.5v.5zM70.5 27.1c-.8 0-1.4-.3-1.9-.8s-.7-1.2-.7-2.1c0-.8.2-1.5.7-2 .5-.5 1.1-.8 1.9-.8s1.4.2 1.9.7.7 1.1.7 1.8v.5h-4.5c0 .6.2 1.1.6 1.5s.8.5 1.3.5c.4 0 .8-.1 1.1-.3s.5-.4.6-.8h.8c-.2.5-.5.9-.9 1.2-.5.5-1 .6-1.6.6zm1.9-3.1c0-.6-.2-1.1-.5-1.4s-.9-.6-1.4-.6c-.5 0-1 .2-1.3.5-.4.3-.6.8-.6 1.4h3.8zM74.9 21.6v.9c.3-.7.9-1 1.7-1v.7h-.2c-.5 0-.8.1-1.1.4-.3.3-.4.7-.4 1.3V27h-.7v-5.5h.7zM84.3 20.1v.6h-2.8v2.6H84v.6h-2.5v2.6h2.8v.5h-3.5v-6.9h3.5zM89.3 21.6l-1.8 2.7 1.8 2.7h-.8l-1.4-2.2-1.3 2.2H85l1.8-2.7-1.8-2.7h.8l1.4 2.2 1.3-2.2h.8z"
            />
        </g>
        <circle
            className="sr4"
            cx={8.7}
            cy={9.4}
            r={9}
            transform="translate(1.259 -.373)"
        />
        <circle
            className="sr4"
            cx={22.2}
            cy={16.9}
            r={3.5}
            transform="translate(1.259 -.373)"
        />
        <circle
            className="sr4"
            cx={23.7}
            cy={23.4}
            r={1}
            transform="translate(1.259 -.373)"
        />
        <circle
            className="sr4"
            cx={15.2}
            cy={23.9}
            r={5.5}
            transform="translate(1.259 -.373)"
        />
        <path
            d="M0 0h29v29H0V0z"
            style={{
                fill: "none",
            }}
        />
    </svg>
)

SquirilRecruiterIcon.defaultProps = {
    width: "128.503",
    height: "36.931",
};

export default SquirilRecruiterIcon
