import * as React from "react"

const SquirilWhiteLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={135.936}
    height={34.375}
    {...props}
  >
    <g data-name="Group 32304">
      <path data-name="Path 24920" d="m43.53 17.749-.22.11" fill="#7052cc" />
      <g data-name="Group 32303" fill="#fff">
        <path
          data-name="Path 24930"
          d="M47.894 27.551a14.534 14.534 0 0 1-7.43-1.911l1.034-3.374a15.06 15.06 0 0 0 3.2 1.307 12.208 12.208 0 0 0 3.374.507 4.567 4.567 0 0 0 3.042-.877 3.1 3.1 0 0 0 1.034-2.5c0-1.443-.956-2.437-2.886-2.984-.137-.039-.507-.156-1.151-.331q-.936-.263-1.755-.527c-.546-.176-.936-.312-1.19-.39-3.081-1.092-4.621-3.1-4.621-6.064a5.657 5.657 0 0 1 2.223-4.8 9.41 9.41 0 0 1 5.869-1.7 14.339 14.339 0 0 1 7.157 1.7l-1.287 3.1a14.033 14.033 0 0 0-5.694-1.384 4.912 4.912 0 0 0-2.847.663 2.707 2.707 0 0 0-1.034 2.223 2.411 2.411 0 0 0 1.892 2.5c.253.1.858.293 1.852.6.975.312 1.618.507 1.911.6a10.045 10.045 0 0 1 4.5 2.574 6.231 6.231 0 0 1 1.345 4.27 5.946 5.946 0 0 1-2.32 5.031 10.027 10.027 0 0 1-6.218 1.767Z"
        />
        <path
          data-name="Path 24931"
          d="M71.079 34.376v-7.274a21.781 21.781 0 0 1 .117-2.223 5.38 5.38 0 0 1-4.914 2.691 6.577 6.577 0 0 1-5.363-2.5 9.987 9.987 0 0 1-2.027-6.528 10.174 10.174 0 0 1 1.931-6.455 6.421 6.421 0 0 1 5.382-2.476 5.678 5.678 0 0 1 5.089 2.613l.332-2.184h3.608v24.141Zm-4-9.906a3.671 3.671 0 0 0 3.061-1.346 7.032 7.032 0 0 0 1.092-4.251 8.106 8.106 0 0 0-1.053-4.66 3.638 3.638 0 0 0-3.159-1.462 3.507 3.507 0 0 0-2.984 1.462 9.321 9.321 0 0 0 0 8.755 3.6 3.6 0 0 0 3.046 1.502Z"
        />
        <path
          data-name="Path 24932"
          d="M91.788 25.873a9.506 9.506 0 0 1-10.783 0 5.966 5.966 0 0 1-2.047-4.914V10.02h4.114v10.783a4.07 4.07 0 0 0 .8 2.789 3.392 3.392 0 0 0 2.554.858 3.3 3.3 0 0 0 2.516-.858 4.036 4.036 0 0 0 .8-2.789V10.02h4.095v10.939a5.966 5.966 0 0 1-2.049 4.914Z"
        />
        <path
          data-name="Path 24933"
          d="M97.677 7.524v-3.51h4.114v3.51Zm0 19.714v-17.2h4.1v17.2Z"
        />
        <path
          data-name="Path 24934"
          d="M106.062 27.239v-17.2h3.451l.526 2.632a5.433 5.433 0 0 1 4.894-2.711 8.133 8.133 0 0 1 1.092.059l-.059 3.432a9.344 9.344 0 0 0-1.306-.1 4.444 4.444 0 0 0-3.392 1.211 4.736 4.736 0 0 0-1.111 3.354v9.321Z"
        />
        <path
          data-name="Path 24935"
          d="M118.62 7.524v-3.51h4.115v3.51Zm0 19.714v-17.2h4.1v17.2Z"
        />
        <path
          data-name="Path 24936"
          d="M133.031 27.472a8.873 8.873 0 0 1-2.925-.409 3.861 3.861 0 0 1-1.833-1.326 5.456 5.456 0 0 1-.936-2.164 15.435 15.435 0 0 1-.273-3.12V3.702l4.1-.273v16.946a6.137 6.137 0 0 0 .546 3.139 2.39 2.39 0 0 0 2.125.819 14.213 14.213 0 0 0 1.989-.175l.117 2.925a3.659 3.659 0 0 0-.39.078c-.253.058-.487.1-.7.136-.234.039-.507.078-.858.117a8.633 8.633 0 0 1-.962.058Z"
        />
      </g>
      <g data-name="small Logo" transform="translate(1.185)" fill="#787fe4">
        <circle data-name="Ellipse 1765" cx={10.668} cy={10.668} r={10.668} />
        <circle
          data-name="Ellipse 1766"
          cx={4.149}
          cy={4.149}
          r={4.149}
          transform="translate(22.522 15.41)"
        />
        <circle
          data-name="Ellipse 1768"
          cx={1.185}
          cy={1.185}
          r={1.185}
          transform="translate(27.263 26.078)"
        />
        <circle
          data-name="Ellipse 1767"
          cx={6.519}
          cy={6.519}
          r={6.519}
          transform="translate(11.853 21.336)"
        />
      </g>
      <path data-name="Rectangle 10446" fill="none" d="M0 0h34.375v34.375H0z" />
    </g>
  </svg>
)

SquirilWhiteLogo.defaultProps ={
    width:135.936,
    height:34.375
}

export default SquirilWhiteLogo
