import * as React from "react"

const DownArrow = ({width, height, props}:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g data-name="Group 30511">
      <g data-name="Group 379">
        <path
          data-name="Path 22060"
          d="M7.444 16.566a5.825 5.825 0 0 0 .817-.578c1.924-1.914 3.831-3.844 5.748-5.765a1.18 1.18 0 0 0 .391-1.127 1.1 1.1 0 0 0-.8-.878 1.111 1.111 0 0 0-1.154.284c-.511.5-1.014 1.015-1.519 1.523l-2.3 2.307c-.067.066-.137.128-.252.234V1.312A1.186 1.186 0 0 0 7.556.053a1.161 1.161 0 0 0-1.5 1.025c-.009.116 0 .233 0 .349v11.139c-.109-.1-.182-.165-.25-.234q-1.896-1.894-3.782-3.79a1.143 1.143 0 0 0-1.2-.323 1.1 1.1 0 0 0-.8.878 1.158 1.158 0 0 0 .37 1.104q2.136 2.141 4.265 4.288c.5.5.985 1.017 1.5 1.5a7.086 7.086 0 0 0 .82.577Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

DownArrow.defaultProps = {
    width: '14',
    height: '16.5'
}

export default DownArrow
