import React from "react";
import { Link } from "gatsby";
import * as Icons from "../../icons/index";
import footer from "./../../json/header-footer.json";
import fosterLogo from "../../images/Foster.svg";
import squirilRecruiterLogo from "../../images/squiril_recruiter_logo.png";
import squirilCandidateLogo from "../../images/squiril_candidate_logo.png";
import "./Footer.scss";

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="row">
        <div className="col">
          <Icons.TheSenseLogo />

          <div className="terms_condition">
            <Link to="/terms-of-service/">{footer.termsOfService}</Link>
            <Link to="/privacy-policy/">{footer.privacyPolicy}</Link>
          </div>
        </div>

        {/* <div className="col product">
          <h2>{footer.product}</h2>

          <ul>
            <li>
              <a href={process.env.GATSBY_SQUIRIL_URL} target="_blank">
                <Icons.FosterTalentIcon height={40} width={40} />
              </a>
            </li>

            <li>
              <a href={process.env.GATSBY_APPLICANT_URL} target="_blank">
                <Icons.SquirilCandidateIcon />
              </a>
            </li>

            <li>
              <a href={process.env.GATSBY_FOSTER_URL} target="_blank">
                <Icons.FosterLogo />
              </a>
            </li>
          </ul>
        </div> */}

        <div className="col">
          <h2>{footer.company}</h2>

          <ul>
            <li>
              <a
                href="https://www.smartsensesolutions.com/contact-us"
                target="_blank"
              >
                {footer.contact}
              </a>
            </li>

            <li>
              <a
                href="https://www.smartsensesolutions.com/smartsense-team"
                target="_blank"
              >
                {footer.team}
              </a>
            </li>

            <li>
              <a
                href="https://www.smartsensesolutions.com/about-us"
                target="_blank"
              >
                {footer.about}
              </a>
            </li>

            <li>
              <a
                href="https://www.smartsensesolutions.com/career"
                target="_blank"
              >
                {footer.career}
              </a>
            </li>
          </ul>
        </div>

        <div className="sparater"></div>

        <div className="col">
          <h2>{footer.fosterTalent}</h2>

          <ul>
            {/* <li>
              <a>{footer.whyHrSense}</a>
            </li>

            <li>
              <a>{footer.features}</a>
            </li> */}

            <li>
              <Link to="/pricings/">{footer.pricing}</Link>
            </li>

            <li>
              <Link to="/contact-us/">{footer.support}</Link>
            </li>

            {/* <li>
              <a>{footer.security}</a>
            </li> */}
          </ul>
        </div>

        <div className="col">
          <div className="hrSense-logo">
            <Icons.FosterTalentIcon height={40} width={40} />
          </div>
          {/* <h2>{footer.connectWithUs}</h2>
          <div className="social-dv">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/smartsense-solutions/mycompany/?viewAsMember=true"
                  target="_blank"
                >
                  <Icons.FooterLinkedIncon />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/smartsense13" target="_blank">
                  <Icons.FooterTwitterIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/smartsensesolutions"
                  target="_blank"
                >
                  <Icons.FooterFbIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/smartsensesolutions?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <Icons.FooterInstaIcon />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <div className="bottom-footer">
        <p>{footer.copyRights}</p>
      </div>
    </div>
  );
};

export default FooterComponent;
