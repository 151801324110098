import * as React from "react";

const TheSenseLogo = ({ width, height, props }: any) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g id="theSense" transform="translate(-79.566 -33.604)">
      <g id="Group_30761" transform="translate(80.334 35)">
        <g id="Path_23026">
          <path
            d="M0,17.5l11.7,13.6L29.4,0L0,17.5z M24.9,4.6l-6.7,11.8L4.4,16.8L24.9,4.6z M11.5,28.2L3,18.5l14.2-0.4
                        L11.5,28.2z"
          />
          <path
            d="M11.8,31.9L-0.8,17.4L30.8-1.4L11.8,31.9z M0.8,17.6l10.9,12.6L28,1.4L0.8,17.6z M11.5,29.1L2,18l16.2-0.5
                        L11.5,29.1z M4.1,18.9l7.3,8.4l5-8.8L4.1,18.9z M2.5,17.3L26.3,3.2l-7.8,13.7L2.5,17.3z M23.5,6L6.3,16.2l11.6-0.3L23.5,6z"
          />
        </g>
        <g id="Path_23027">
          <path
            d="M21.8,16.8l-18,30.9l29.6-17.1L21.8,16.8z M22,19.6l8.3,10l-14.5,0.5L22,19.6z M8.3,43.1l6.6-11.4l13.9-0.5
                        L8.3,43.1z"
          />
          <path
            d="M2.4,49l19.4-33.1l12.4,14.8L2.4,49z M21.9,17.6L5.1,46.3l27.4-15.9L21.9,17.6z M6.9,44.5l7.7-13.2l14.1-0.5
                        l0.3,0.9L6.9,44.5z M15.2,32.3l-5.5,9.5l17.1-9.9L15.2,32.3z M15,30.6L22,18.7L31.4,30L15,30.6z M22.1,20.5l-5.3,9.1l12.5-0.5
                        L22.1,20.5z"
          />
        </g>
      </g>
      <g>
        <path
          d="M127.3,56.6V63c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.6,0.3,1.1,0.3h1.6v2.8h-2.1c-2.8,0-4.2-1.4-4.2-4.1v-6.4h-1.6
                    v-2.8h1.6v-3.3h3.4v3.3h3v2.8H127.3z"
        />
        <path
          d="M142.9,54.3c0.8,0.4,1.4,1.1,1.8,1.9c0.4,0.9,0.7,1.9,0.7,3.1v7.8H142v-7.3c0-1.1-0.3-1.9-0.8-2.4
                    c-0.5-0.6-1.2-0.9-2.2-0.9c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.4-0.8,2.4v7.3h-3.4V49.4h3.4v6.1c0.4-0.6,1-1,1.7-1.4
                    c0.7-0.3,1.5-0.5,2.4-0.5C141.2,53.6,142.1,53.9,142.9,54.3z"
        />
        <path
          d="M160.8,61.5h-9.7c0.1,1,0.4,1.7,1,2.3c0.6,0.5,1.3,0.8,2.2,0.8c1.2,0,2.1-0.5,2.7-1.6h3.6
                    c-0.4,1.3-1.1,2.3-2.2,3.2s-2.4,1.2-4,1.2c-1.3,0-2.4-0.3-3.4-0.9c-1-0.6-1.8-1.4-2.4-2.4c-0.6-1-0.9-2.2-0.9-3.6
                    c0-1.4,0.3-2.6,0.8-3.6c0.6-1,1.3-1.8,2.4-2.4c1-0.6,2.2-0.8,3.5-0.8c1.3,0,2.4,0.3,3.4,0.8c1,0.5,1.8,1.3,2.3,2.3
                    s0.8,2.1,0.8,3.4C160.9,60.7,160.9,61.1,160.8,61.5z M157.4,59.2c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.5-1.4-0.8-2.2-0.8
                    c-0.8,0-1.5,0.3-2.1,0.8c-0.6,0.5-0.9,1.2-1,2.1H157.4z"
        />
        <path
          d="M166,66.7c-0.9-0.4-1.7-1-2.2-1.7s-0.8-1.6-0.8-2.7h3.6c0,0.7,0.3,1.2,0.7,1.6c0.4,0.4,1,0.6,1.8,0.6
                    c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.7-0.9,0.7-1.5c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.6-1.1-0.8c-0.5-0.2-1.1-0.4-1.9-0.6
                    c-1.1-0.3-2-0.6-2.7-0.9c-0.7-0.3-1.3-0.8-1.8-1.4S163,56.1,163,55c0-1,0.2-1.9,0.7-2.6c0.5-0.7,1.2-1.3,2.1-1.7
                    c0.9-0.4,1.9-0.6,3.1-0.6c1.7,0,3.1,0.4,4.2,1.3c1.1,0.8,1.7,2,1.8,3.5h-3.7c0-0.6-0.3-1.1-0.7-1.4s-1.1-0.6-1.8-0.6
                    c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.8-0.6,1.5c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.5,1.1,0.7c0.4,0.2,1.1,0.4,1.9,0.6
                    c1.1,0.3,2,0.6,2.7,1c0.7,0.3,1.3,0.8,1.8,1.4c0.5,0.6,0.7,1.5,0.7,2.5c0,0.9-0.2,1.7-0.7,2.5c-0.5,0.8-1.1,1.4-2,1.8
                    c-0.9,0.5-2,0.7-3.2,0.7C168,67.3,166.9,67.1,166,66.7z"
        />
        <path
          d="M190.3,61.5h-9.7c0.1,1,0.4,1.7,1,2.3c0.6,0.5,1.3,0.8,2.2,0.8c1.2,0,2.1-0.5,2.7-1.6h3.6
                    c-0.4,1.3-1.1,2.3-2.2,3.2s-2.4,1.2-4,1.2c-1.3,0-2.4-0.3-3.4-0.9c-1-0.6-1.8-1.4-2.4-2.4c-0.6-1-0.9-2.2-0.9-3.6
                    c0-1.4,0.3-2.6,0.8-3.6c0.6-1,1.3-1.8,2.4-2.4c1-0.6,2.2-0.8,3.5-0.8c1.3,0,2.4,0.3,3.4,0.8s1.8,1.3,2.3,2.3
                    c0.6,1,0.8,2.1,0.8,3.4C190.3,60.7,190.3,61.1,190.3,61.5z M186.9,59.2c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.5-1.4-0.8-2.2-0.8
                    c-0.8,0-1.5,0.3-2.1,0.8s-0.9,1.2-1,2.1H186.9z"
        />
        <path
          d="M204,55.1c1,1,1.5,2.4,1.5,4.2v7.8h-3.4v-7.3c0-1.1-0.3-1.9-0.8-2.4c-0.5-0.6-1.2-0.9-2.2-0.9
                    c-0.9,0-1.7,0.3-2.2,0.9c-0.5,0.6-0.8,1.4-0.8,2.4v7.3h-3.4V53.8h3.4v1.7c0.4-0.6,1-1,1.7-1.4c0.7-0.3,1.5-0.5,2.3-0.5
                    C201.7,53.6,203,54.1,204,55.1z"
        />
        <path
          d="M210.8,66.8c-0.9-0.4-1.5-0.9-2.1-1.6c-0.5-0.7-0.8-1.4-0.8-2.2h3.4c0.1,0.5,0.3,0.9,0.8,1.3
                    c0.4,0.3,1,0.5,1.6,0.5c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.5-0.6,0.5-1c0-0.4-0.2-0.8-0.7-1c-0.4-0.2-1.1-0.5-2.1-0.7
                    c-1-0.2-1.8-0.5-2.4-0.7c-0.6-0.3-1.2-0.6-1.6-1.2c-0.5-0.5-0.7-1.2-0.7-2.1c0-0.7,0.2-1.4,0.6-2s1-1.1,1.8-1.4
                    c0.8-0.4,1.7-0.5,2.8-0.5c1.6,0,2.8,0.4,3.8,1.2s1.5,1.9,1.6,3.2h-3.2c0-0.5-0.3-0.9-0.7-1.3c-0.4-0.3-0.9-0.5-1.6-0.5
                    c-0.6,0-1.1,0.1-1.4,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.4,0.2,0.8,0.7,1c0.4,0.2,1.1,0.5,2.1,0.7c1,0.2,1.8,0.5,2.4,0.7
                    s1.2,0.7,1.6,1.2s0.7,1.2,0.7,2.1c0,0.8-0.2,1.5-0.6,2.1c-0.4,0.6-1,1.1-1.8,1.4s-1.7,0.5-2.8,0.5
                    C212.7,67.3,211.7,67.2,210.8,66.8z"
        />
        <path
          d="M234,61.5h-9.7c0.1,1,0.4,1.7,1,2.3c0.6,0.5,1.3,0.8,2.2,0.8c1.2,0,2.1-0.5,2.7-1.6h3.6
                    c-0.4,1.3-1.1,2.3-2.2,3.2c-1.1,0.8-2.4,1.2-4,1.2c-1.3,0-2.4-0.3-3.4-0.9c-1-0.6-1.8-1.4-2.4-2.4c-0.6-1-0.9-2.2-0.9-3.6
                    c0-1.4,0.3-2.6,0.8-3.6c0.6-1,1.3-1.8,2.4-2.4c1-0.6,2.2-0.8,3.5-0.8c1.3,0,2.4,0.3,3.4,0.8s1.8,1.3,2.3,2.3s0.8,2.1,0.8,3.4
                    C234.1,60.7,234.1,61.1,234,61.5z M230.6,59.2c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.5-1.4-0.8-2.2-0.8c-0.8,0-1.5,0.3-2.1,0.8
                    c-0.6,0.5-0.9,1.2-1,2.1H230.6z"
        />
      </g>
    </g>
  </svg>
);

TheSenseLogo.defaultProps = {
  width: "156.168",
  height: "50.431",
};

export default TheSenseLogo;
