import * as React from "react";

const totalHrsense = ({ width, height, props }: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 135.4 135.4"
    style={{
      enableBackground: "new 0 0 135.4 135.4",
    }}
    xmlSpace="preserve"
    {...props}
    width={width}
    height={height}
  >
    <style>{".totalHrsense1{fill:#787fe4}"}</style>
    <g id="total" transform="translate(-717.066 -2032.133)">
      <g
        style={{
          enableBackground: "new",
        }}
      >
        <path
          className="totalHrsense1"
          d="M772.2 2093.6c-.3-.3-.8-.5-1.5-.5-1 0-1.7.4-2.1 1.3-.4.9-.7 2.2-.7 4.1.4-.6.9-1.1 1.7-1.4.7-.3 1.5-.5 2.4-.5 1.7 0 3.1.5 4.1 1.6 1 1.1 1.5 2.6 1.5 4.5 0 1.3-.3 2.4-.8 3.3-.5 1-1.3 1.7-2.2 2.2-1 .5-2.1.8-3.5.8-2.7 0-4.5-.8-5.6-2.5s-1.6-4.1-1.6-7.2c0-3.3.6-5.7 1.7-7.3s2.9-2.4 5.3-2.4c1.3 0 2.4.3 3.3.8.9.5 1.6 1.2 2 2 .5.8.7 1.7.8 2.7h-4.1c-.1-.7-.4-1.2-.7-1.5zm-3.2 7.1c-.5.4-.7 1.1-.7 1.9 0 .8.2 1.5.7 2 .5.5 1.1.7 2 .7.8 0 1.4-.2 1.8-.7.4-.5.7-1.1.7-1.9 0-.8-.2-1.5-.6-1.9-.4-.5-1.1-.7-1.9-.7-.9-.1-1.5.2-2 .6zM781.8 2092.1c1.2-1.7 3-2.6 5.6-2.6 2.6 0 4.4.9 5.6 2.6 1.2 1.7 1.7 4.1 1.7 7 0 3-.6 5.4-1.7 7.1-1.2 1.7-3 2.6-5.6 2.6-2.6 0-4.4-.9-5.6-2.6-1.2-1.7-1.7-4.1-1.7-7.1 0-2.9.6-5.3 1.7-7zm8 3c-.4-.9-1.2-1.4-2.4-1.4s-2 .5-2.4 1.4c-.4.9-.6 2.3-.6 4 0 1.2.1 2.2.2 2.9s.4 1.4.8 1.9 1.1.7 1.9.7 1.5-.2 1.9-.7c.4-.5.7-1.1.8-1.9s.2-1.7.2-2.9c.1-1.7-.1-3-.4-4zM797.9 2091.5c.8-.8 1.9-1.1 3.1-1.1 1.3 0 2.3.4 3.1 1.1.8.8 1.2 1.8 1.2 3.2 0 1.4-.4 2.5-1.2 3.2-.8.8-1.8 1.1-3.1 1.1s-2.3-.4-3.1-1.1c-.8-.8-1.2-1.8-1.2-3.2 0-1.4.4-2.5 1.2-3.2zm1.8 3.2c0 1.2.4 1.8 1.2 1.8.4 0 .7-.1.9-.4.2-.3.3-.7.3-1.3 0-1.2-.4-1.8-1.3-1.8-.6-.1-1.1.5-1.1 1.7zm14.6-4.1-10 18.3H800l10-18.3h4.3zm-4.1 10.9c.8-.8 1.8-1.1 3.1-1.1s2.3.4 3.1 1.1c.8.8 1.2 1.8 1.2 3.2s-.4 2.5-1.2 3.2c-.8.8-1.8 1.1-3.1 1.1s-2.3-.4-3.1-1.1c-.8-.8-1.2-1.8-1.2-3.2s.4-2.4 1.2-3.2zm2.2 1.9c-.2.3-.3.7-.3 1.4 0 1.2.4 1.8 1.2 1.8.4 0 .7-.1.9-.4.2-.3.3-.7.3-1.3s-.1-1-.3-1.3c-.2-.3-.5-.5-.9-.5s-.7 0-.9.3z"
        />
      </g>
      <g id="Ellipse_1064-3" transform="translate(717.066 2032.134)">
        <path
          id="Path_22854-2"
          d="M67.7 0C30.3 0 0 30.3 0 67.7s30.3 67.7 67.7 67.7 67.7-30.3 67.7-67.7C135.4 30.3 105.1 0 67.7 0zm0 116.3c-26.8 0-48.6-21.8-48.6-48.6s21.8-48.6 48.6-48.6 48.6 21.8 48.6 48.6-21.8 48.6-48.6 48.6z"
          style={{
            fill: "#e6eae9",
          }}
        />
      </g>
      <g id="Ellipse_1064" transform="translate(749.777 2032.133)">
        <path
          id="Path_22854"
          className="totalHrsense1"
          d="M35 0h-.2v19.1h.2c26.8 0 48.6 21.8 48.6 48.6S61.8 116.3 35 116.3c-9 0-17.8-2.5-25.5-7.2L0 125.6c10.5 6.4 22.6 9.7 35 9.7 37.4 0 67.7-30.3 67.7-67.7C102.7 30.3 72.4 0 35 0z"
        />
      </g>
    </g>
  </svg>
);

totalHrsense.defaultProps = {
  width: "135",
  height: "135",
};
export default totalHrsense;
