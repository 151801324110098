import * as React from "react";

const RightArrowIcons = ({ width, height, props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width={width}
    height={height}
  >
    <path
      d="M8.292 0a1.231 1.231 0 0 0-.821.3L.34 6.688a.964.964 0 0 0 0 1.47 1.26 1.26 0 0 0 1.642 0l6.31-5.648L14.6 8.158a1.26 1.26 0 0 0 1.642 0 .964.964 0 0 0 0-1.47L9.113.3a1.231 1.231 0 0 0-.821-.3z"
      transform="rotate(90 4.231 4.231)"
      style={{
        fill: "#27aabe",
      }}
    />
  </svg>
);

RightArrowIcons.defaultProps = {
  width: "8",
  height: "16",
};

export default RightArrowIcons;
