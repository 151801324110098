import * as React from "react";

const ConversationalChatbotIcon = ({ width, height, props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
    <g transform="translate(-483.5 -255.476)" fill="#ef8830">
      <rect
        data-name="Rectangle 6557"
        width={width}
        height={height}
        rx={6}
        transform="translate(483.5 255.476)"
        opacity={0.1}
      />
      <g data-name="Group 31205">
        <path
          data-name="Path 24343"
          d="M503.395 266.545h6.788a.931.931 0 0 0 .11.033 2.183 2.183 0 0 1 1.8 2.11q.013 1.919 0 3.838a2.17 2.17 0 0 1-1.847 2.1c-.212.03-.429.031-.637.044l.37 1h.564a1.512 1.512 0 0 1 1.553 1.551v1.953a1.513 1.513 0 0 1-1.54 1.547h-.588l-.049.172a3.827 3.827 0 0 1-2.587 2.714 7.568 7.568 0 0 1-.78.179h-6.112a.593.593 0 0 0-.093-.03 3.823 3.823 0 0 1-3.167-2.52c-.066-.164-.107-.338-.162-.515h-.642a1.518 1.518 0 0 1-1.4-1.039c-.029-.09-.051-.182-.077-.273v-2.464a.743.743 0 0 0 .035-.09 1.484 1.484 0 0 1 .873-1.054.192.192 0 0 0 .133-.212c0-.954-.006-1.908 0-2.861a.231.231 0 0 0-.159-.247 1.5 1.5 0 0 1-.814-1.7 1.512 1.512 0 0 1 2.947 0 1.5 1.5 0 0 1-.813 1.7.241.241 0 0 0-.16.263c.006.914 0 1.829 0 2.743v.176l.052.016a.708.708 0 0 0 .075-.134 3.861 3.861 0 0 1 3.833-2.905h.581v-.195c0-1.234-.007-2.469 0-3.7a2.076 2.076 0 0 1 1.062-1.875 5.114 5.114 0 0 1 .851-.325m-1.913 7.1h-.677a2.858 2.858 0 0 0-2.85 2.862q-.007 1.7 0 3.4a2.854 2.854 0 0 0 2.85 2.861q2.7.01 5.392 0a2.853 2.853 0 0 0 2.846-2.849c.006-1.144 0-2.289 0-3.433a2.84 2.84 0 0 0-.576-1.706.275.275 0 0 0-.249-.128c-1.321 0-2.643 0-3.964.005a.563.563 0 0 0-.238.056c-.572.281-1.142.568-1.712.852-.465.232-.819.012-.82-.507v-1.415m1.008.7c.405-.2.774-.4 1.155-.571a1.3 1.3 0 0 1 .511-.121c1.892-.008 3.784 0 5.676-.005a1.18 1.18 0 0 0 1.261-1.258v-3.55a1.184 1.184 0 0 0-1.276-1.283h-6.063a1.183 1.183 0 0 0-1.261 1.273v5.517m7.568 5.363c.2 0 .382.008.565 0a.491.491 0 0 0 .467-.484q.01-1.025 0-2.051a.484.484 0 0 0-.43-.483c-.2-.019-.4 0-.6 0Zm-13.124-3.027c-.2 0-.383-.008-.566 0a.491.491 0 0 0-.467.484q-.01 1.025 0 2.051a.484.484 0 0 0 .43.483c.2.019.4 0 .6 0Zm-.5-5.053a.5.5 0 1 0-.5-.5.511.511 0 0 0 .5.5"
        />
        <path
          data-name="Path 24344"
          d="M503.488 280.721h-.956a.506.506 0 1 1-.006-1.009h1.945a.505.505 0 1 1 .006 1.009h-.989"
        />
        <path
          data-name="Path 24345"
          d="M500.474 277.69v-.5a.5.5 0 0 1 1-.009q.011.512 0 1.024a.5.5 0 0 1-1-.008v-.5"
        />
        <path
          data-name="Path 24346"
          d="M506.522 277.689v.5a.503.503 0 1 1-1.006-.006q-.007-.5 0-.99a.503.503 0 1 1 1.006.006v.487"
        />
        <path
          data-name="Path 24347"
          d="M506.772 270.114h-2.7a.5.5 0 0 1-.49-.713.518.518 0 0 1 .519-.3H509.49a.508.508 0 1 1 0 1.009h-2.719"
        />
        <path
          data-name="Path 24348"
          d="M506.81 271.124h2.7a.5.5 0 0 1 .49.713.517.517 0 0 1-.519.3h-5.388a.508.508 0 1 1 0-1.009h2.719"
        />
      </g>
    </g>
  </svg>
);

ConversationalChatbotIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default ConversationalChatbotIcon;
