import * as React from "react";

const InfoGraphicIcon = ({ width, height, props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
    <defs>
      <style>{".infoGraphic-2{fill:#ef8830}"}</style>
    </defs>
    <g id="Infographic" transform="translate(-884.5 -255.476)">
      <rect
        id="Rectangle_6556"
        data-name="Rectangle 6556"
        width={width}
        height={height}
        rx={6}
        transform="translate(884.5 255.476)"
        style={{
          opacity: 0.1,
          fill: "#ef8830",
        }}
      />
      <g
        id="Group_31204"
        data-name="Group 31204"
        transform="translate(898.872 269.441)"
      >
        <g id="Group_31205" data-name="Group 31205">
          <path
            id="Path_24343"
            data-name="Path 24343"
            className="infoGraphic-2"
            d="M10.5 0h8.384a1.149 1.149 0 0 0 .135.041 2.7 2.7 0 0 1 2.227 2.606q.016 2.37 0 4.74a2.68 2.68 0 0 1-2.281 2.6c-.262.037-.53.038-.787.055l.457 1.231h.7a1.868 1.868 0 0 1 1.918 1.915q.006 1.206 0 2.412a1.868 1.868 0 0 1-1.9 1.91h-.726l-.06.212a4.727 4.727 0 0 1-3.195 3.352 9.347 9.347 0 0 1-.964.22H6.849a.733.733 0 0 0-.115-.037 4.722 4.722 0 0 1-3.911-3.113c-.081-.2-.132-.417-.2-.636H1.83a1.875 1.875 0 0 1-1.736-1.285C.058 16.113.031 16 0 15.886V12.85a.918.918 0 0 0 .043-.112 1.833 1.833 0 0 1 1.083-1.307.237.237 0 0 0 .164-.262 346.923 346.923 0 0 1 0-3.534.286.286 0 0 0-.2-.305 1.853 1.853 0 0 1-1.006-2.1 1.867 1.867 0 0 1 3.64 0 1.856 1.856 0 0 1-1 2.1.3.3 0 0 0-.2.325c.007 1.129 0 2.259 0 3.388v.218l.064.02a.874.874 0 0 0 .088-.165 4.768 4.768 0 0 1 4.742-3.589h.717v-.245c0-1.524-.008-3.049 0-4.573A2.564 2.564 0 0 1 9.447.4 6.317 6.317 0 0 1 10.5 0M8.135 8.775h-.836a3.53 3.53 0 0 0-3.521 3.535q-.009 2.1 0 4.2A3.525 3.525 0 0 0 7.3 20.043q3.33.012 6.66 0a3.524 3.524 0 0 0 3.515-3.518c.008-1.413.005-2.827 0-4.241a3.507 3.507 0 0 0-.712-2.108.339.339 0 0 0-.308-.157c-1.632.006-3.264 0-4.9.007a.7.7 0 0 0-.294.07c-.707.347-1.41.7-2.115 1.053-.574.286-1.012.015-1.012-.626V8.776m1.245.861c.5-.25.956-.494 1.427-.705a1.606 1.606 0 0 1 .631-.149c2.337-.01 4.674-.006 7.011-.007a1.458 1.458 0 0 0 1.557-1.558V2.833a1.462 1.462 0 0 0-1.573-1.583h-7.487a1.461 1.461 0 0 0-1.564 1.57v6.816m9.347 6.623c.245 0 .472.01.7 0a.606.606 0 0 0 .577-.6q.012-1.266 0-2.533a.6.6 0 0 0-.531-.6c-.244-.023-.492 0-.745 0zm-16.2-3.74c-.245 0-.473-.01-.7 0a.607.607 0 0 0-.577.6q-.012 1.266 0 2.533a.6.6 0 0 0 .531.6c.244.023.492 0 .745 0zM1.9 6.279a.623.623 0 1 0-.617-.623.631.631 0 0 0 .617.623"
          />
          <path
            id="Path_24344"
            data-name="Path 24344"
            className="infoGraphic-2"
            d="M159.81 294.479h-1.18a.625.625 0 1 1-.008-1.246h2.4a.624.624 0 1 1 .008 1.246h-1.222"
            transform="translate(-149.196 -276.971)"
          />
          <path
            id="Path_24345"
            data-name="Path 24345"
            className="infoGraphic-2"
            d="M124.233 227.008v-.622a.621.621 0 0 1 1.241-.011q.013.632 0 1.265a.621.621 0 0 1-1.241-.01c-.006-.207 0-.415 0-.622"
            transform="translate(-117.342 -213.242)"
          />
          <path
            id="Path_24346"
            data-name="Path 24346"
            className="infoGraphic-2"
            d="M237.738 227.006v.622a.621.621 0 1 1-1.242-.007q-.009-.612 0-1.223a.622.622 0 1 1 1.243.007v.6"
            transform="translate(-223.377 -213.242)"
          />
          <path
            id="Path_24347"
            data-name="Path 24347"
            className="infoGraphic-2"
            d="M196.345 58.221h-3.338a.621.621 0 0 1-.605-.881.64.64 0 0 1 .642-.367h6.655a.627.627 0 1 1 0 1.247h-3.358"
            transform="translate(-181.675 -53.813)"
          />
          <path
            id="Path_24348"
            data-name="Path 24348"
            className="infoGraphic-2"
            d="M196.435 101.988h3.338a.621.621 0 0 1 .6.88.639.639 0 0 1-.641.368h-6.657a.627.627 0 1 1-.005-1.247h3.358"
            transform="translate(-181.718 -96.332)"
          />
        </g>
      </g>
    </g>
  </svg>
);

InfoGraphicIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default InfoGraphicIcon;
