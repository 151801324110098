import * as React from "react";

const CrossIcon = ({ width, height, props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g data-name="Group 29928" fill="#e8696b">
      <path
        data-name="Path 21948"
        d="M0 7.41v-.819l.052-.416a6.722 6.722 0 0 1 2.55-4.6A6.783 6.783 0 0 1 9.17.37a6.964 6.964 0 0 1 4.749 7.579 6.872 6.872 0 0 1-1.693 3.688 6.841 6.841 0 0 1-4.057 2.252c-.263.045-.53.071-.8.106h-.765c-.044-.008-.088-.019-.132-.024-.261-.034-.525-.053-.784-.1a6.831 6.831 0 0 1-3.626-1.9 6.814 6.814 0 0 1-1.96-3.757C.062 7.936.035 7.675 0 7.41Zm7.01-6.312a5.9 5.9 0 0 0-5.924 5.877A5.888 5.888 0 0 0 6.974 12.9a5.887 5.887 0 0 0 5.924-5.889A5.9 5.9 0 0 0 7.01 1.099Z"
      />
      <path
        data-name="Path 21949"
        d="M7.015 7.726c-.11.116-.215.23-.323.339q-.588.59-1.178 1.176a.531.531 0 0 1-.587.144.515.515 0 0 1-.353-.446.562.562 0 0 1 .2-.487q.692-.688 1.383-1.378a1.007 1.007 0 0 1 .124-.09c-.521-.521-1.014-1.017-1.51-1.512a.553.553 0 0 1-.183-.528.537.537 0 0 1 .853-.322 1.293 1.293 0 0 1 .131.12q.672.67 1.342 1.341a1.123 1.123 0 0 1 .1.13c.06-.056.1-.087.131-.121q.7-.694 1.391-1.388a.544.544 0 0 1 .582-.165.535.535 0 0 1 .271.83 1.265 1.265 0 0 1-.127.142q-.671.671-1.343 1.34a.887.887 0 0 1-.122.087L7.786 7a.647.647 0 0 1 .129.079c.468.464.937.928 1.4 1.4a.538.538 0 0 1-.18.911.552.552 0 0 1-.607-.166q-.693-.7-1.39-1.389c-.042-.034-.074-.064-.123-.109Z"
      />
    </g>
  </svg>
);

CrossIcon.defaultProps = {
  width: "13.987",
  height: "13.991",
};

export default CrossIcon;
