import * as React from "react"

const FooterTwitterIcon = ({width, height, props}:any) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
        <g data-name="Group 174">
            <path data-name="Path 148" d="M22.9 12.131a9.122 9.122 0 0 1-2.6.718 4.541 4.541 0 0 0 2-2.512 9.082 9.082 0 0 1-2.871 1.1 4.555 4.555 0 0 0-7.874 3.1 5.193 5.193 0 0 0 .112 1.032 12.881 12.881 0 0 1-9.342-4.739 4.643 4.643 0 0 0-.606 2.288 4.533 4.533 0 0 0 2.019 3.769 4.647 4.647 0 0 1-2.064-.561v.067a4.528 4.528 0 0 0 3.634 4.442 4.222 4.222 0 0 1-1.189.157 3.547 3.547 0 0 1-.852-.09 4.521 4.521 0 0 0 4.24 3.141 9.14 9.14 0 0 1-5.631 1.929A8.672 8.672 0 0 1 .8 25.9a12.9 12.9 0 0 0 6.954 2.041 12.807 12.807 0 0 0 12.9-12.9l-.022-.583a9.738 9.738 0 0 0 2.268-2.327z" transform="translate(-.8 -10)"/>
        </g>
    </svg>


)

FooterTwitterIcon.defaultProps = {
    width: '22.096',
    height: '17.946'
}

export default FooterTwitterIcon