import * as React from "react";

const WhatsappChatbotIcon = ({ width, height, props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g transform="translate(-884.5 -255.476)">
      <rect
        data-name="Rectangle 6556"
        width={width}
        height={height}
        rx={6}
        transform="translate(884.5 255.476)"
        fill="#2bbbad"
        opacity={0.1}
      />
      <g data-name="Group 31250">
        <path
          data-name="Path 24376"
          d="M905.818 283.343v1.208a1.324 1.324 0 1 1-2.634.019v-1.179a23.991 23.991 0 0 1-2.55-1.057 7.6 7.6 0 0 1-3.836-5.152c-.073-.315-.184-.41-.488-.389a8.529 8.529 0 0 1-1.054 0 1.341 1.341 0 0 1-1.292-1.35 1.319 1.319 0 0 1 1.347-1.278c.351-.006.7-.013 1.054 0 .241.01.353-.052.415-.316a7.8 7.8 0 0 1 5.084-5.814c.42-.161.865-.255 1.323-.387 0-.441-.005-.88 0-1.318a1.318 1.318 0 1 1 2.633-.019c.01.438-.138.98.068 1.284.183.271.762.259 1.155.4a7.8 7.8 0 0 1 5.168 5.79c.069.282.156.4.452.378.35-.024.7-.012 1.054 0a1.315 1.315 0 0 1 1.321 1.261 1.334 1.334 0 0 1-1.274 1.364c-.394.024-.792-.012-1.185.018a.439.439 0 0 0-.329.228 7.878 7.878 0 0 1-6.355 6.221c-.011 0-.019.02-.076.084m-1.328-13.128a5.267 5.267 0 1 0 5.278 5.274 5.282 5.282 0 0 0-5.278-5.274"
          fill="#2bbbad"
        />
      </g>
    </g>
  </svg>
);

WhatsappChatbotIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default WhatsappChatbotIcon;
