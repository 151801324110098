import * as React from "react"

const EmailIcon = ({ width, height, props }: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        {...props}
    >
        <g data-name="Group 23917">
            <g data-name="Group 23916">
                <path
                    data-name="Path 16908"
                    d="M17.384 0H1.159A1.159 1.159 0 0 0 0 1.159v.659l9.271 3.926 9.271-3.928v-.658A1.159 1.159 0 0 0 17.384 0Z"
                    fill="#787fe4"
                />
            </g>
        </g>
        <g data-name="Group 23919">
            <g data-name="Group 23918">
                <path
                    data-name="Path 16909"
                    d="M9.5 6.907a.574.574 0 0 1-.452 0L0 3.077v8.512a1.159 1.159 0 0 0 1.159 1.159h16.225a1.159 1.159 0 0 0 1.159-1.159V3.077Z"
                    fill="#787fe4"
                />
            </g>
        </g>
    </svg>
)

EmailIcon.defaultProps = {
    width: '18.543',
    height: '12.748'
}

export default EmailIcon
