import * as React from "react"

const beforeHrsense = ({width, height, props}:any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 110.3 110.3"
    style={{
      enableBackground: "new 0 0 110.3 110.3",
    }}
    xmlSpace="preserve"
    {...props}

    width={width} height={height}
  >
    <style>{".beforeHrSense1{fill:#ff3f89}"}</style>
    <g id="before" transform="translate(-618.853 -1245.219)">
      <g id="Group_31037">
        <g id="Group_31030">
          <g id="Ellipse_1064-3" transform="translate(618.853 1245.219)">
            <path
              id="Path_22854-2"
              d="M55.1 0C24.7 0 0 24.7 0 55.1s24.7 55.1 55.1 55.1c30.5 0 55.1-24.7 55.1-55.1C110.3 24.7 85.6 0 55.1 0zm0 94.7c-21.9 0-39.6-17.7-39.6-39.6s17.7-39.6 39.6-39.6c21.9 0 39.6 17.7 39.6 39.6C94.7 77 77 94.7 55.1 94.7z"
              style={{
                fill: "#e6e7ea",
              }}
            />
          </g>
          <g transform="translate(618.853 1245.219)" id="Group_31029">
            <defs>
              <path id="SVGID_1_" d="M0 0h110.3v110.3H0z" />
            </defs>
            <clipPath id="SVGID_00000084522582237907760580000009974596573396876710_">
              <use
                xlinkHref="#SVGID_1_"
                style={{
                  overflow: "visible",
                }}
              />
            </clipPath>
            <g
              id="Group_31029-2"
              style={{
                clipPath:
                  "url(#SVGID_00000084522582237907760580000009974596573396876710_)",
              }}
            >
              <path
                id="Path_24198"
                className="beforeHrSense1"
                d="M110.3 55.2c0 30.5-24.7 55.1-55.2 55.1C24.7 110.3 0 85.6 0 55.2V55h15.6v.1c0 21.9 17.7 39.6 39.6 39.6S94.7 77 94.7 55.2 77 15.6 55.2 15.6h-.7L54.7 0h.5c30.4 0 55.1 24.7 55.1 55.2"
              />
            </g>
          </g>
        </g>
        <g
          style={{
            enableBackground: "new",
          }}
        >
          <path
            className="beforeHrSense1"
            d="M651.9 1304.9v-2.2l5.1-7.2h3.1v7.1h1.3v2.4H660v2.1h-2.7v-2.1h-5.4zm5.5-6.4-2.7 4.1h2.7v-4.1zM663.7 1296.7c.7-1.1 1.9-1.6 3.4-1.6s2.7.5 3.4 1.6 1.1 2.5 1.1 4.3c0 1.9-.4 3.3-1.1 4.4-.7 1.1-1.9 1.6-3.4 1.6s-2.7-.5-3.4-1.6c-.7-1.1-1.1-2.5-1.1-4.4 0-1.8.4-3.3 1.1-4.3zm4.9 1.8c-.2-.6-.7-.9-1.5-.9-.7 0-1.2.3-1.5.9-.2.6-.4 1.4-.4 2.5 0 .7 0 1.3.1 1.8s.3.9.5 1.2c.3.3.6.4 1.2.4.5 0 .9-.1 1.2-.4.3-.3.4-.7.5-1.2s.1-1.1.1-1.8c.1-1.1 0-1.9-.2-2.5zM681.3 1299c.6.7.9 1.6.9 2.8v5.2h-2.7v-4.8c0-.6-.2-1.1-.5-1.4-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.3-.5.8-.5 1.4v4.8h-2.7v-11.8h2.7v4.1c.3-.4.7-.7 1.1-.9.5-.2 1-.4 1.6-.4 1.1 0 1.9.3 2.5 1zM688.1 1298.4c.5-.3 1-.4 1.6-.4v2.9h-.8c-.7 0-1.2.1-1.5.4-.3.3-.5.8-.5 1.5v4.1h-2.7v-8.9h2.7v1.5c.3-.4.7-.8 1.2-1.1zM692.5 1306.7c-.6-.3-1.1-.6-1.4-1.1-.4-.5-.5-1-.6-1.6h2.7c0 .3.2.6.4.8.3.2.6.3.9.3.3 0 .6-.1.8-.2s.3-.3.3-.5c0-.3-.1-.4-.4-.6-.3-.1-.7-.3-1.3-.4-.6-.1-1.2-.3-1.6-.5-.4-.2-.8-.4-1.1-.8s-.5-.8-.5-1.4c0-.5.1-1 .4-1.4.3-.4.7-.8 1.2-1 .5-.2 1.2-.4 2-.4 1.1 0 2 .3 2.6.8.6.6 1 1.3 1.1 2.2h-2.5c0-.3-.2-.6-.4-.7s-.5-.3-.9-.3c-.3 0-.6.1-.7.2-.2.1-.3.3-.3.5 0 .3.1.4.4.6.3.1.7.3 1.3.4.7.2 1.2.3 1.6.5.4.2.8.4 1.1.8.3.4.5.9.5 1.5 0 .5-.1 1-.4 1.4-.3.4-.7.7-1.3 1-.5.2-1.2.4-1.9.4-.7-.1-1.4-.2-2-.5z"
          />
        </g>
      </g>
    </g>
  </svg>
)

beforeHrsense.defaultProps = {
    width: '110',
    height: '110'
}
export default beforeHrsense
