import * as React from "react"

const MobileMenuIcon = ({width, height, props}:any) => (
  
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height}
        {...props}
    >
        <g fill="#111334">
        <path
            data-name="Path 24199"
            d="M0 10.88a1.348 1.348 0 0 1 1.467-1.027q12.426.019 24.852.009a1.193 1.193 0 0 1 1.272.924 1.414 1.414 0 0 1-.159 1.106 1.13 1.13 0 0 1-.875.548c-.107.009-.216 0-.324 0q-12.4 0-24.8.009A1.331 1.331 0 0 1 0 11.423Z"
        />
        <path
            data-name="Path 24200"
            d="M0 .987A2.873 2.873 0 0 1 .4.336a1.967 1.967 0 0 1 .65-.317.841.841 0 0 1 .293-.011h24.941a1.766 1.766 0 0 1 .587.073 1.28 1.28 0 0 1 .763 1.369 1.209 1.209 0 0 1-1.077 1.111c-.117.007-.234.005-.351.005q-12.36 0-24.723.012A1.372 1.372 0 0 1 0 1.59v-.6"
        />
        <path
            data-name="Path 24201"
            d="M0 20.771a1.3 1.3 0 0 1 1.419-1.042q12.479.021 24.957.009a1.161 1.161 0 0 1 1.22.949 1.394 1.394 0 0 1-.155 1.05 1.125 1.125 0 0 1-.815.55 3.9 3.9 0 0 1-.458.017q-12.344 0-24.688.011a1.368 1.368 0 0 1-1.48-1Z"
        />
        </g>
    </svg>
)

MobileMenuIcon.defaultProps = {
    width: '27.648',
    height: '22.325'
}

export default MobileMenuIcon
