import * as React from "react";

const afterHrsense = ({ width, height, props }: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110.3 110.3"
    style={{
      enableBackground: "new 0 0 110.3 110.3",
    }}
    xmlSpace="preserve"
    {...props}
    width={width}
    height={height}
  >
    <style>{".afterHrSense1{fill:#787fe4}"}</style>
    <g id="after" transform="translate(-1190.853 -1245.218)">
      <g id="Ellipse_1064-3" transform="translate(1190.853 1245.218)">
        <path
          id="Path_22854-2"
          d="M55.1 0C24.7 0 0 24.7 0 55.1s24.7 55.1 55.1 55.1c30.5 0 55.1-24.7 55.1-55.1C110.3 24.7 85.6 0 55.1 0zm0 94.7c-21.9 0-39.6-17.7-39.6-39.6s17.7-39.6 39.6-39.6 39.6 17.7 39.6 39.6C94.7 77 77 94.7 55.1 94.7z"
          style={{
            fill: "#e6e7ea",
          }}
        />
      </g>
      <g id="Ellipse_1064-4" transform="translate(1246 1245.218)">
        <path
          id="Path_22856"
          className="afterHrSense1"
          d="m54.9 49.8-15.5 1.7C37.5 31.1 20.4 15.6 0 15.6V0c28.4 0 52.1 21.5 54.9 49.8z"
        />
      </g>
      <g
        style={{
          enableBackground: "new",
        }}
      >
        <path
          className="afterHrSense1"
          d="M1225 1297.9v-2.5h4.4v11.7h-2.8v-9.1h-1.6zM1236.7 1297.6c-.2-.2-.5-.3-.9-.3-.6 0-1 .3-1.3.8-.3.5-.4 1.4-.4 2.5.2-.4.6-.7 1-.9.4-.2.9-.3 1.5-.3 1 0 1.9.3 2.5 1 .6.7 1 1.6 1 2.8 0 .8-.2 1.5-.5 2-.3.6-.8 1-1.4 1.4-.6.3-1.3.5-2.1.5-1.6 0-2.8-.5-3.4-1.5-.6-1-1-2.5-1-4.4 0-2 .3-3.5 1-4.5s1.8-1.5 3.3-1.5c.8 0 1.5.2 2 .5s1 .7 1.2 1.2c.3.5.5 1.1.5 1.7h-2.5c-.2-.5-.3-.8-.5-1zm-2 4.4c-.3.3-.5.7-.5 1.2s.1.9.4 1.2c.3.3.7.4 1.2.4s.8-.1 1.1-.4c.3-.3.4-.7.4-1.2s-.1-.9-.4-1.2c-.3-.3-.7-.4-1.2-.4-.3-.1-.7.1-1 .4zM1249.8 1299c.6.7.9 1.6.9 2.8v5.2h-2.7v-4.8c0-.6-.2-1.1-.5-1.4-.3-.3-.7-.5-1.2-.5s-.9.2-1.2.5c-.3.3-.5.8-.5 1.4v4.8h-2.7v-11.8h2.7v4.1c.3-.4.7-.7 1.1-.9.5-.2 1-.4 1.6-.4 1 0 1.8.3 2.5 1zM1256.5 1298.4c.5-.3 1-.4 1.6-.4v2.9h-.8c-.7 0-1.2.1-1.5.4-.3.3-.5.8-.5 1.5v4.1h-2.7v-8.9h2.7v1.5c.4-.4.8-.8 1.2-1.1zM1261 1306.7c-.6-.3-1.1-.6-1.4-1.1-.4-.5-.5-1-.6-1.6h2.7c0 .3.2.6.4.8.3.2.6.3.9.3.3 0 .6-.1.8-.2s.3-.3.3-.5c0-.3-.1-.4-.4-.6-.3-.1-.7-.3-1.3-.4-.6-.1-1.2-.3-1.6-.5-.4-.2-.8-.4-1.1-.8-.3-.4-.5-.8-.5-1.4 0-.5.1-1 .4-1.4.3-.4.7-.8 1.2-1 .5-.2 1.2-.4 2-.4 1.1 0 2 .3 2.6.8s1 1.3 1.1 2.2h-2.5c0-.3-.2-.6-.4-.7s-.5-.3-.9-.3c-.3 0-.6.1-.7.2s-.3.3-.3.5c0 .3.1.4.4.6.3.1.7.3 1.3.4.7.2 1.2.3 1.6.5.4.2.8.4 1.1.8s.5.9.5 1.5c0 .5-.1 1-.4 1.4-.3.4-.7.7-1.3 1-.5.2-1.2.4-1.9.4-.7-.1-1.4-.2-2-.5z"
        />
      </g>
    </g>
  </svg>
);

afterHrsense.defaultProps = {
  width: "110",
  height: "110",
};

export default afterHrsense;
