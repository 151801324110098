import * as React from "react";

const SquirilNewLogoIcon = ({ width, height, props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g data-name="Group 32144">
      <path
        data-name="Path 23718"
        d="M178.794 89.415a13.523 13.523 0 0 1-6.922-1.778l.962-3.133a14.4 14.4 0 0 0 2.98 1.224 11.253 11.253 0 0 0 3.141.466 4.319 4.319 0 0 0 2.841-.809 2.867 2.867 0 0 0 .962-2.324q0-2.011-2.681-2.783-.19-.058-1.064-.313t-1.639-.5q-.765-.241-1.1-.372-4.3-1.516-4.3-5.654a5.3 5.3 0 0 1 2.062-4.474 8.787 8.787 0 0 1 5.472-1.574 13.276 13.276 0 0 1 6.66 1.589l-1.2 2.885a13.038 13.038 0 0 0-5.3-1.282 4.687 4.687 0 0 0-2.659.619 2.456 2.456 0 0 0-.955 2.062 2.237 2.237 0 0 0 1.763 2.317q.35.131 1.719.568l1.778.568a9.45 9.45 0 0 1 4.19 2.39 5.81 5.81 0 0 1 1.26 3.978 5.534 5.534 0 0 1-2.157 4.685 9.381 9.381 0 0 1-5.814 1.639"
        transform="translate(-132.26 -58.851)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23719"
        d="M251.367 111.591v-6.776q0-1.006.1-2.069a5 5 0 0 1-4.576 2.506 6.123 6.123 0 0 1-5-2.332A9.333 9.333 0 0 1 240 96.844a9.522 9.522 0 0 1 1.792-6.019 5.991 5.991 0 0 1 5.013-2.3 5.263 5.263 0 0 1 4.736 2.434l.306-2.04h3.353V111.4zm-3.73-9.239a3.426 3.426 0 0 0 2.856-1.253 6.507 6.507 0 0 0 1.02-3.964 7.509 7.509 0 0 0-.984-4.335 3.386 3.386 0 0 0-2.936-1.362 3.264 3.264 0 0 0-2.776 1.369 8.682 8.682 0 0 0 .007 8.153 3.3 3.3 0 0 0 2.813 1.392"
        transform="translate(-183.237 -74.66)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23720"
        d="M326.217 104.841a8.856 8.856 0 0 1-10.048.007 5.574 5.574 0 0 1-1.9-4.576V90.087h3.831v10.04a3.718 3.718 0 0 0 .75 2.594 3.129 3.129 0 0 0 2.383.8 3.052 3.052 0 0 0 2.339-.794 3.766 3.766 0 0 0 .736-2.6v-10.04h3.818v10.186a5.568 5.568 0 0 1-1.909 4.568"
        transform="translate(-238.808 -75.83)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23721"
        d="M383.5 67.859h3.833v3.264H383.5zm0 5.6h3.818V89.47H383.5z"
        transform="translate(-290.61 -59.198)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23722"
        d="M414.525 105.885V89.87h3.206l.5 2.448a5.06 5.06 0 0 1 4.561-2.521 7.229 7.229 0 0 1 1.02.058l-.058 3.191a8.169 8.169 0 0 0-1.21-.087 4.1 4.1 0 0 0-3.155 1.122 4.407 4.407 0 0 0-1.042 3.118v8.685z"
        transform="translate(-313.825 -75.613)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23723"
        d="M461.006 67.859h3.833v3.264h-3.833zm0 5.6h3.818V89.47h-3.818z"
        transform="translate(-348.605 -59.198)"
        style={{
          fill: "#243858",
        }}
      />
      <path
        data-name="Path 23724"
        d="M497.715 88.042a8.255 8.255 0 0 1-2.732-.386 3.548 3.548 0 0 1-1.712-1.231 5.109 5.109 0 0 1-.874-2.011 14.254 14.254 0 0 1-.248-2.9V65.907l3.818-.248v15.782a5.75 5.75 0 0 0 .51 2.922 2.221 2.221 0 0 0 1.982.765 13.6 13.6 0 0 0 1.851-.16l.1 2.725c-.01 0-.129.024-.357.073s-.447.09-.656.124-.474.068-.794.1a8.475 8.475 0 0 1-.889.051"
        transform="translate(-371.907 -57.552)"
        style={{
          fill: "#243858",
        }}
      />
      <g data-name="Group 32287">
        <path
          data-name="Path 23910"
          d="m18.784 62.418-.02.106a10.512 10.512 0 0 1-.353-1.595 12 12 0 0 0 .374 1.488"
          transform="translate(-14.631 -48.422)"
          style={{
            fill: "#787fe4",
          }}
        />
        <path
          data-name="Path 23911"
          d="M121.989 81.858a3.737 3.737 0 1 1-3.811-3.733.651.651 0 0 1 .075 0 3.736 3.736 0 0 1 3.736 3.736"
          transform="translate(-91.009 -62.085)"
          style={{
            fill: "#787fe4",
          }}
        />
        <path
          data-name="Path 23912"
          d="M64.106 110.374a7.054 7.054 0 0 1-7.053 7.053c-.083 0-.163 0-.242-.006a11.573 11.573 0 0 1-4.244-1.607h-.006a7.053 7.053 0 1 1 11.545-5.437"
          transform="translate(-39.736 -82.111)"
          style={{
            fill: "#787fe4",
          }}
        />
        <path
          data-name="Path 23913"
          d="M21.085 10.543a10.544 10.544 0 0 1-10.542 10.542c-.124 0-.243 0-.362-.009a17.29 17.29 0 0 1-6.344-2.4h-.009a10.543 10.543 0 1 1 17.258-8.127"
          style={{
            fill: "#787fe4",
          }}
        />
        <path
          data-name="Path 23914"
          d="M133.935 128.174a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3"
          transform="translate(-104.375 -100.83)"
          style={{
            fill: "#787fe4",
          }}
        />
        <path
          data-name="Path 23915"
          d="m20.239 68.027-.006.037v-.006a.13.13 0 0 1 .009-.032"
          transform="translate(-16.077 -54.063)"
          style={{
            fill: "#787fe4",
          }}
        />
      </g>
    </g>
  </svg>
);

SquirilNewLogoIcon.defaultProps = {
  width: "128.503",
  height: "36.931",
};

export default SquirilNewLogoIcon;
