import * as React from "react"

const FooterFbIcon = ({width, height, props}: any) => (

    <svg id="socialFacebook" xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
        <g id="Group_173" data-name="Group 173" transform="translate(0)">
        <path id="Path_144" data-name="Path 144" d="M32.284,8.379V5.621a1.373,1.373,0,0,1,1.368-1.368h1.368V.8H32.284a4.148,4.148,0,0,0-4.126,4.148V8.4H25.4v3.431h2.758V22.864h4.126V11.832h2.758l1.368-3.453Z" transform="translate(-25.4 -0.8)"/>
        </g>
    </svg>

)

FooterFbIcon.defaultProps = {
    width: '11.009',
    height: '22.064'
}

export default FooterFbIcon