import * as React from "react";

const PersonalisedCareerIcon = ({ width, height, props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
    <g transform="translate(-484 -164.124)">
      <rect
        data-name="Rectangle 6554"
        width={width}
        height={height}
        rx={6}
        transform="translate(484 164.124)"
        fill="#27aabe"
        opacity={0.15}
      />
      <g data-name="Group 31297">
        <path
          data-name="Path 24349"
          d="M494 191.285V180.1a.3.3 0 0 0 .031-.066 2.262 2.262 0 0 1 2.326-1.9h3.53v-3.01h7.545v3.012h.226c1.137 0 2.275-.005 3.412 0a2.256 2.256 0 0 1 2.236 2.241q.009 5.318 0 10.635a2.248 2.248 0 0 1-2.224 2.233q-7.428.009-14.857 0a2.183 2.183 0 0 1-1.767-.9 2.778 2.778 0 0 1-.458-1.06m10.41-5.592H509.484a2.266 2.266 0 0 0 2.316-2.334v-2.853a.769.769 0 0 0-.862-.859h-14.575a.766.766 0 0 0-.854.848v2.853a2.267 2.267 0 0 0 2.344 2.345h5.047v-1.495h1.51Zm7.39.769c-.049.028-.071.037-.091.051a3.9 3.9 0 0 1-2.324.694c-1.583-.01-3.167 0-4.751 0h-.234v1.5h-1.507v-1.5h-5.109a3.734 3.734 0 0 1-2.084-.624l-.187-.12c0 .077-.008.131-.008.185v4.269a.759.759 0 0 0 .824.821h14.651a.759.759 0 0 0 .819-.826v-4.288Zm-5.892-9.825h-4.5v1.487h4.5Z"
          fill="#27aabe"
        />
      </g>
    </g>
  </svg>
);

PersonalisedCareerIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default PersonalisedCareerIcon;
