import * as React from "react";

const BlogIcon = ({ width, height, props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g transform="translate(-483.5 -164.476)" fill="#2bbbad">
      <rect
        data-name="Rectangle 6554"
        width={width}
        height={height}
        rx={6}
        transform="translate(483.5 164.476)"
        opacity={0.15}
      />
      <path d="M503.224 182.441a.705.705 0 1 1-.705.705.705.705 0 0 1 .705-.705Zm2.468.705a.705.705 0 1 0 .705-.705.705.705 0 0 0-.705.705Zm-6.347 0a.705.705 0 1 0 .705-.705.705.705 0 0 0-.704.705Zm12.32 6.613a2.116 2.116 0 0 1-2.991 2.992l-3.871-3.862a.7.7 0 0 1-.177-.294l-.842-2.777a.705.705 0 0 1 .863-.885l2.846.788a.705.705 0 0 1 .31.181Zm-5.74-1.741 2.608 2.6 1-1-2.6-2.592-1.432-.4Zm4.744 2.741-.136-.141-1 1 .136.135a.707.707 0 1 0 1-1Zm-7.017-.825c-.13.006-.262.009-.392.009a8.534 8.534 0 0 1-3.245-.628.706.706 0 0 0-.541 0l-3.211 1.357 1.082-2.563a.705.705 0 0 0-.123-.743 5.979 5.979 0 0 1-1.579-4.014c0-3.652 3.417-6.623 7.616-6.623s7.616 2.971 7.616 6.623a5.8 5.8 0 0 1-.632 2.584.705.705 0 1 0 1.264.625 7.2 7.2 0 0 0 .778-3.209c.001-4.434-4.052-8.034-9.025-8.034s-9.027 3.6-9.027 8.034a7.311 7.311 0 0 0 1.644 4.606l-1.588 3.76a.705.705 0 0 0 .924.924l4.546-1.919a9.963 9.963 0 0 0 3.5.624c.15 0 .3 0 .454-.01a.705.705 0 0 0-.062-1.409Zm0 0" />
    </g>
  </svg>
);

BlogIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default BlogIcon;
