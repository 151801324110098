import * as React from "react";

const CaseStudyIcon = ({ width, height, props }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
    <g transform="translate(-884 -164.087)">
      <rect
        data-name="Rectangle 6555"
        width={width}
        height={height}
        rx={6}
        transform="translate(884 164.087)"
        fill="red"
        opacity={0.1}
      />
      <path
        data-name="Path 24354"
        d="M907.226 193.654h-7.758a2.641 2.641 0 0 1-2.638-2.638v-12.691a2.641 2.641 0 0 1 2.638-2.638h5.931a2.137 2.137 0 0 1 1.66.787l2.352 2.873a2.015 2.015 0 0 1 .454 1.272.528.528 0 1 1-1.057 0 .955.955 0 0 0-.215-.6l-2.351-2.876a1.084 1.084 0 0 0-.842-.4h-5.932a1.583 1.583 0 0 0-1.581 1.581v12.69a1.583 1.583 0 0 0 1.581 1.581h7.758a1.583 1.583 0 0 0 1.581-1.581v-3.7a.528.528 0 1 1 1.057 0v3.7a2.641 2.641 0 0 1-2.638 2.64Z"
        fill="red"
      />
      <path
        data-name="Path 24355"
        d="M906.518 181.324h-.148a1.79 1.79 0 0 1-1.79-1.79v-3.319a.528.528 0 0 1 1.057 0v3.319a.733.733 0 0 0 .733.733h.148a.528.528 0 1 1 0 1.057Z"
        fill="red"
      />
      <path
        data-name="Path 24356"
        d="M900.709 191.533a.528.528 0 0 1-.5-.7l.757-2.272a.528.528 0 0 1 .128-.207l9.1-9.1a1.371 1.371 0 0 1 1.937 0l.1.1.223.223a1.369 1.369 0 0 1 0 1.936l-9.1 9.1a.528.528 0 0 1-.207.128l-2.272.757a.528.528 0 0 1-.166.035Zm1.219-2.515-.384 1.151 1.151-.384 9.012-9.012a.312.312 0 0 0 0-.442l-.222-.222-.1-.1a.313.313 0 0 0-.442 0Z"
        fill="red"
      />
      <path
        data-name="Path 24357"
        d="M910.175 186.027a.528.528 0 0 1-.374-.9l.758-.758a1.187 1.187 0 0 0 0-1.676l-.012-.012a.528.528 0 0 1 .749-.745l.009.009a2.246 2.246 0 0 1 0 3.171l-.758.758a.527.527 0 0 1-.372.153Z"
        fill="red"
      />
      <path
        data-name="Path 24358"
        d="M909.512 184.244a.527.527 0 0 1-.374-.155l-1.515-1.515a.528.528 0 0 1 .747-.747l1.515 1.515a.528.528 0 0 1-.374.9Z"
        fill="red"
      />
      <path
        data-name="Path 24359"
        d="M900.352 188.193a.528.528 0 0 1-.528-.528 1.938 1.938 0 0 1 1.938-1.938h1.938a.528.528 0 1 1 0 1.057h-1.938a.881.881 0 0 0-.881.881.528.528 0 0 1-.529.528Z"
        fill="red"
      />
      <path
        data-name="Path 24360"
        d="M904.228 184.142h-3.169a1.762 1.762 0 0 1 0-3.523h2.29a.528.528 0 1 1 0 1.057h-2.29a.705.705 0 1 0 0 1.409h3.171a.528.528 0 1 1 0 1.057Z"
        fill="red"
      />
    </g>
  </svg>
);

CaseStudyIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default CaseStudyIcon;
