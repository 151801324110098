import * as React from "react";
const FosterTalentIcon = ({ width, height, fill, props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    viewBox="0 0 360 360"
    width={width}
    height={height}
    {...props}
    fill={fill}
  >
    <g id="Layer_1-2">
      <g id="Group_35">
        <path
          id="Rectangle_82"
          d="M24 10c-7.73 0-13.99 6.27-14 14v312c0 7.73 6.27 13.99 14 14h312c7.73 0 13.99-6.27 14-14V24c0-7.73-6.27-13.99-14-14H24Zm0-10h312c13.25 0 24 10.75 24 24v312c0 13.25-10.75 24-24 24H24c-13.25 0-24-10.75-24-24V24C0 10.75 10.75 0 24 0Z"
        />
        <g id="Group_66954">
          <path
            id="Path_140"
            d="M84 270.53V89h123.99v35.54h-79.98v37.24h72.15v35.8h-72.15v72.95H84Z"
          />
          <path
            id="Path_141"
            d="M220.67 209.35v-12.9h55.86v12.9h-20.06v53.11H240.6v-53.11h-19.93Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

FosterTalentIcon.defaultProps = {
  width: "360",
  height: "360",
  fill: "#121e2a",
};

export default FosterTalentIcon;
