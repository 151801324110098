import * as React from "react";

const NewsIcon = ({ width, height, props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g transform="translate(-483.5 -255.476)" fill="#27aabe">
      <rect
        data-name="Rectangle 6557"
        width={width}
        height={height}
        rx={6}
        transform="translate(483.5 255.476)"
        opacity={0.1}
      />
      <g data-name="Group 30841">
        <path
          data-name="Path 23335"
          d="M512.803 267.93v12.868a1.094 1.094 0 0 0-.035.127 2.876 2.876 0 0 1-.378 1.078 3.029 3.029 0 0 1-2.738 1.539h-11.088c-.107 0-.215 0-.321.009a2.557 2.557 0 0 1-2.767-2.429 89.519 89.519 0 0 1 0-4.113 2.558 2.558 0 0 1 1.9-2.352 5.205 5.205 0 0 1 1.311-.092h.581v-6.384a.7.7 0 0 1 .469-.742 1.279 1.279 0 0 1 .367-.044q5.908 0 11.815-.006a.822.822 0 0 1 .888.541m-12.463 14.24h9.298a1.824 1.824 0 0 0 .5-.059 1.7 1.7 0 0 0 1.282-1.734v-11.41c0-.06-.005-.119-.008-.183h-10.774v11.6a3.543 3.543 0 0 1-.3 1.788m-1.081-6.217c-.459 0-.893-.017-1.326 0a1.161 1.161 0 0 0-1.083 1.085c-.021.512-.013 1.026-.013 1.539 0 .823-.006 1.647.01 2.47a1.208 1.208 0 0 0 2.411-.147v-4.789Z"
        />
        <path
          data-name="Path 23339"
          d="M506.017 276.397h-2.945a.681.681 0 0 1-.663-.931.644.644 0 0 1 .542-.428 1.9 1.9 0 0 1 .253-.014h5.637a1.359 1.359 0 0 1 .368.039.681.681 0 0 1-.178 1.331c-.485.01-.97 0-1.456 0h-1.557"
        />
        <path
          data-name="Path 23340"
          d="M505.422 279.821c-.773 0-1.546.006-2.319 0a.731.731 0 0 1-.7-.989.7.7 0 0 1 .593-.475 1.785 1.785 0 0 1 .237-.014h4.363a1.343 1.343 0 0 1 .361.039.735.735 0 0 1-.2 1.438c-.669.008-1.339 0-2.008 0h-.329"
        />
        <path
          data-name="Path 23341"
          d="M505.184 273.08h-2.116a.687.687 0 0 1-.523-1.15.7.7 0 0 1 .547-.224h4.215a.679.679 0 0 1 .728.7.687.687 0 0 1-.751.675h-2.1"
        />
      </g>
    </g>
  </svg>
);

NewsIcon.defaultProps = {
  width: "40",
  height: "40",
};

export default NewsIcon;
