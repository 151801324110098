import * as React from "react";

const CallComponent = ({width, height, props}:any) => (
  <svg
    id="Group_30733"
    data-name="Group 30733"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Rectangle_6285"
          data-name="Rectangle 6285"
          className="call-1"
          d="M0 0h17v17H0z"
        />
      </clipPath>
      <style>{".call-1{fill:#787fe4}"}</style>
    </defs>
    <g
      id="Group_30732"
      data-name="Group 30732"
      style={{
        clipPath: "url(#clip-path)",
      }}
    >
      <path
        id="Path_23057"
        data-name="Path 23057"
        className="call-1"
        d="M4.856 6.521a23.458 23.458 0 0 0 5.61 5.611l.511-.729a1.9 1.9 0 0 1 2.82-.5q1.132.788 2.261 1.579a1.891 1.891 0 0 1 .66 2.6 3.384 3.384 0 0 1-2.945 1.873 7.9 7.9 0 0 1-4.179-.9A18.33 18.33 0 0 1 .871 7.276a7.794 7.794 0 0 1-.848-4.049A3.371 3.371 0 0 1 2.166.148a1.81 1.81 0 0 1 2.192.6c.633.862 1.25 1.737 1.839 2.63a1.856 1.856 0 0 1-.45 2.509c-.282.217-.579.414-.891.636"
      />
    </g>
  </svg>
);

CallComponent.defaultProps = {
    width: '17',
    height: '17'
}

export default CallComponent;
