import React, { useEffect, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import * as Icons from "./../../icons/index";
import header from "./../../json/header-footer.json";
import "./Header.scss";

const HeaderComponent = () => {
  const breakpoints = useBreakpoint();
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [subMenu, setSubMenu] = useState({
    feature: true,
    resource: true,
  });

  useEffect(() => {
    if (breakpoints.sm) {
      setSubMenu({ feature: false, resource: false });
    }
  }, []);

  const toggleMenu = () => {
    setOpenMenu(!isOpenMenu);
  };

  const onsubMenuToggle = (param: string) => {
    if (breakpoints.sm) {
      if (param === "feature") {
        setSubMenu({ feature: !subMenu.feature, resource: false });
      } else {
        setSubMenu({ feature: true, resource: !subMenu.resource });
      }
    }
  };

  const hideSubMenu = () => {
    setSubMenu({ feature: false, resource: false });
  };

  return (
    <div className="mainContainer">
      <Link to="/" className="logo">
        <Icons.FosterTalentIcon height={40} width={40} />
      </Link>

      <a className="mobile-menu" onClick={toggleMenu}>
        {isOpenMenu ? <Icons.CloseIcon /> : <Icons.MobileMenuIcon />}
      </a>

      <div
        className={
          isOpenMenu
            ? "mainContainer__headerRight d-block"
            : "mainContainer__headerRight"
        }
      >
        <ul className="mainContainer__headerRight__menu">
          {/* <li>
            <a
              className={subMenu.feature ? "active" : ""}
              onClick={(e) => onsubMenuToggle("feature")}
            >
              {header.features}
            </a>

            {subMenu.feature && (
              <div className="inner-menu">
                <h3>Features</h3>
                <ul>
                  <li>
                    <a>
                      <div className="menu-icon">
                        <Icons.ConversationalChatbotIcon />
                      </div>

                      <div className="menu-content">
                        <label>Conversational chatbot</label>
                        <span>Conversational chatbot</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="menu-icon">
                        <Icons.InternnalMobilityIcon />
                      </div>

                      <div className="menu-content">
                        <label>Internal Mobility</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="menu-icon">
                        <Icons.PersonalisedCareerIcon />
                      </div>

                      <div className="menu-content">
                        <label>Personalised career site</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="menu-icon">
                        <Icons.WhatsappChatbotIcon />
                      </div>

                      <div className="menu-content">
                        <label>Application Tracking System</label>
                        <span>ATS</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li> */}

          <li>
            <Link to="/pricings/" activeClassName="active">
              {header.pricing}
            </Link>
          </li>

          {/* <li>
            <a
              className={subMenu.resource ? "active" : ""}
              onClick={(e) => onsubMenuToggle("resource")}
            >
              {header.resources}
            </a>
            {subMenu.resource && (
              <div className="inner-menu">
                <h3>Features</h3>
                <ul>
                  <li>
                    <Link to={"/Blogs/"}>
                      <div className="menu-icon">
                        <Icons.BlogIcon />
                      </div>

                      <div className="menu-content">
                        <label>Blogs</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to={"/CaseStudy"}>
                      <div className="menu-icon">
                        <Icons.CaseStudyIcon />
                      </div>

                      <div className="menu-content">
                        <label>Case Study</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to={"/News"}>
                      <div className="menu-icon">
                        <Icons.NewsIcon />
                      </div>

                      <div className="menu-content">
                        <label>News</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <a>
                      <div className="menu-icon">
                        <Icons.InfoGraphicIcon />
                      </div>

                      <div className="menu-content">
                        <label>Infographics</label>
                        <span>Lorem ipsum generate</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li> */}

          {/* <li>
            <a>{header.customers}</a>
          </li> */}

          <li>
            <Link to="/contact-us/" activeClassName="active">
              {header.support}
            </Link>
          </li>
        </ul>

        <div className="mainContainer__headerRight__signup-dv">
          <a href={process.env.GATSBY_LOGIN_URL} className="sign-in">
            {header.signIn}
          </a>
          <div className="signup-dv">
            <a
              href={process.env.GATSBY_SIGNUP_URL}
              className="primary-green-btn"
            >
              {header.signUp}
              <img
                src="https://hr-sense-public-assets.s3.ap-south-1.amazonaws.com/website/button_right_arrow.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      {breakpoints.sm && (subMenu.feature || subMenu.resource) && (
        <div className="menu-overlay" onMouseOver={hideSubMenu}></div>
      )}
    </div>
  );
};

export default HeaderComponent;
