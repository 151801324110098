import * as React from "react"

const FooterLinkedIn = ({width, height, props}:any) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} {...props}>
        <path d="M23.692 16.333v8.531h-4.955v-7.97c0-2.01-.725-3.366-2.5-3.366a2.743 2.743 0 0 0-2.548 1.8 3.261 3.261 0 0 0-.164 1.215v8.321H8.593s.07-13.486 0-14.888h4.955v2.1c0 .023-.023.023-.023.047h.023v-.047a4.87 4.87 0 0 1 4.441-2.454c3.249.003 5.703 2.13 5.703 6.711zM3.4 2.8A2.6 2.6 0 0 0 .6 5.371a2.563 2.563 0 0 0 2.735 2.571h.023A2.579 2.579 0 1 0 3.4 2.8zM.9 24.864h4.935V9.975H.9z" transform="translate(-.6 -2.8)" />
    </svg>
)

FooterLinkedIn.defaultProps = {
    width: '23.092',
    height: '22.064'
}

export default FooterLinkedIn