import * as React from "react";

const HangoutIcon = ({ width, height, props }) => (
  <svg
    id="hangouts"
    xmlns="http://www.w3.org/2000/svg"
    width={22.919}
    height={26.077}
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#111334}"}</style>
    </defs>
    <g id="Group_31221" data-name="Group 31221">
      <path
        id="Path_24362"
        data-name="Path 24362"
        className="cls-1"
        d="M34.356 3.356a11.46 11.46 0 0 1 16.207 16.207 11.375 11.375 0 0 1-7.339 3.331v2.419a.764.764 0 0 1-.764.764 8.3 8.3 0 0 1-4.581-1.459 13.661 13.661 0 0 1-3.6-3.574A17.337 17.337 0 0 1 31 11.46a11.384 11.384 0 0 1 3.356-8.104zM41.7 24.5v-2.345a.764.764 0 0 1 .764-.764 9.932 9.932 0 1 0-9.932-9.932c-.004 5.489 4.227 12.38 9.168 13.041z"
        transform="translate(-31)"
      />
    </g>
    <g
      id="Group_31222"
      data-name="Group 31222"
      transform="translate(4.584 7.64)"
    >
      <path
        id="Path_24363"
        data-name="Path 24363"
        className="cls-1"
        d="M271.764 150h4.584a.764.764 0 0 1 .764.764v4.584a.764.764 0 0 1-.764.764h-.764v2.288a.764.764 0 0 1-.764.764 3.824 3.824 0 0 1-3.82-3.82v-4.584a.764.764 0 0 1 .764-.76zm.764 5.348a2.3 2.3 0 0 0 1.528 2.161v-2.161a.764.764 0 0 1 .764-.764h.764v-3.056h-3.056z"
        transform="translate(-271 -150)"
      />
    </g>
    <g
      id="Group_31223"
      data-name="Group 31223"
      transform="translate(12.224 7.64)"
    >
      <path
        id="Path_24364"
        data-name="Path 24364"
        className="cls-1"
        d="M121.764 150h4.584a.764.764 0 0 1 .764.764v4.584a.764.764 0 0 1-.764.764h-.764v2.288a.764.764 0 0 1-.764.764 3.824 3.824 0 0 1-3.82-3.82v-4.584a.764.764 0 0 1 .764-.76zm.764 5.348a2.3 2.3 0 0 0 1.528 2.161v-2.161a.764.764 0 0 1 .764-.764h.764v-3.056h-3.056z"
        transform="translate(-121 -150)"
      />
    </g>
  </svg>
);

HangoutIcon.defaultProps = {
    width: '22.919',
    height: '26.077'
}

export default HangoutIcon;
