import * as React from "react";

const FooterFbIcon = ({ width, height, props }: any) => (
  <svg version="1.1" id="hrRecruiter" width={width} height={height} {...props}>
    <g>
      <path
        d="M80.6,11.2c0.7,0.4,1.3,1,1.7,1.8c0.4,0.8,0.6,1.7,0.6,2.8V23h-3.1v-6.7c0-1-0.2-1.7-0.7-2.2s-1.1-0.8-2-0.8
                c-0.9,0-1.5,0.3-2,0.8c-0.5,0.5-0.7,1.3-0.7,2.2V23h-3.1V6.7h3.1v5.6c0.4-0.5,0.9-0.9,1.6-1.2c0.7-0.3,1.4-0.5,2.2-0.5
                C79.1,10.6,79.9,10.8,80.6,11.2z"
      />
      <path
        d="M90.5,11.2c0.6-0.4,1.4-0.5,2.2-0.5v3.2h-0.8c-1,0-1.7,0.2-2.2,0.7c-0.5,0.5-0.7,1.2-0.7,2.4V23h-3.1V10.8h3.1
                v1.9C89.3,12.1,89.8,11.6,90.5,11.2z"
      />
      <path
        d="M97.1,22.6c-0.9-0.4-1.5-0.9-2-1.6c-0.5-0.7-0.8-1.5-0.8-2.4h3.3c0,0.6,0.3,1.1,0.7,1.5s1,0.5,1.7,0.5
                c0.7,0,1.3-0.2,1.7-0.5c0.4-0.3,0.6-0.8,0.6-1.4c0-0.5-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-1-0.4-1.7-0.6
                c-1-0.3-1.8-0.6-2.4-0.9c-0.6-0.3-1.2-0.7-1.6-1.3c-0.4-0.6-0.7-1.4-0.7-2.3c0-0.9,0.2-1.7,0.7-2.4C95.5,8.8,96.2,8.3,97,8
                c0.8-0.4,1.8-0.5,2.8-0.5c1.6,0,2.9,0.4,3.9,1.2c1,0.8,1.5,1.8,1.6,3.2h-3.4c0-0.5-0.3-1-0.7-1.3s-1-0.5-1.7-0.5
                c-0.6,0-1.1,0.2-1.4,0.5c-0.4,0.3-0.5,0.8-0.5,1.3c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,1,0.4,1.7,0.6
                c1,0.3,1.8,0.6,2.4,0.9c0.6,0.3,1.2,0.7,1.6,1.3c0.5,0.6,0.7,1.4,0.7,2.3c0,0.8-0.2,1.6-0.6,2.3c-0.4,0.7-1,1.3-1.9,1.7
                s-1.8,0.6-2.9,0.6C99,23.2,98,23,97.1,22.6z"
      />
      <path
        d="M119.4,17.8h-8.9c0.1,0.9,0.4,1.6,0.9,2.1c0.5,0.5,1.2,0.7,2,0.7c1.1,0,2-0.5,2.4-1.5h3.3
                c-0.4,1.2-1,2.1-2,2.9c-1,0.8-2.2,1.1-3.7,1.1c-1.2,0-2.2-0.3-3.2-0.8s-1.7-1.3-2.2-2.2s-0.8-2.1-0.8-3.3c0-1.3,0.3-2.4,0.8-3.3
                c0.5-1,1.2-1.7,2.2-2.2c0.9-0.5,2-0.8,3.2-0.8c1.2,0,2.2,0.2,3.1,0.7c0.9,0.5,1.6,1.2,2.1,2.1c0.5,0.9,0.8,2,0.8,3.2
                C119.5,17.1,119.4,17.5,119.4,17.8z M116.3,15.8c0-0.8-0.3-1.4-0.9-1.9s-1.2-0.7-2-0.7c-0.8,0-1.4,0.2-1.9,0.7
                c-0.5,0.5-0.8,1.1-1,1.9H116.3z"
      />
      <path
        d="M132,12c0.9,0.9,1.3,2.2,1.3,3.8V23h-3.1v-6.7c0-1-0.2-1.7-0.7-2.2c-0.5-0.5-1.1-0.8-2-0.8
                c-0.9,0-1.5,0.3-2,0.8s-0.7,1.3-0.7,2.2V23h-3.1V10.8h3.1v1.5c0.4-0.5,0.9-0.9,1.6-1.2c0.6-0.3,1.3-0.5,2.1-0.5
                C129.9,10.6,131.1,11.1,132,12z"
      />
      <path
        d="M138.2,22.7c-0.8-0.4-1.4-0.8-1.9-1.5c-0.5-0.6-0.7-1.3-0.8-2h3.1c0.1,0.5,0.3,0.9,0.7,1.2
                c0.4,0.3,0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4c0.3-0.2,0.5-0.5,0.5-0.9c0-0.4-0.2-0.7-0.6-0.9s-1-0.4-1.9-0.6
                c-0.9-0.2-1.7-0.4-2.2-0.7c-0.6-0.2-1.1-0.6-1.5-1.1c-0.4-0.5-0.6-1.1-0.6-2c0-0.7,0.2-1.3,0.6-1.8c0.4-0.6,0.9-1,1.7-1.3
                c0.7-0.3,1.6-0.5,2.6-0.5c1.5,0,2.6,0.4,3.5,1.1c0.9,0.7,1.3,1.7,1.4,2.9h-2.9c0-0.5-0.2-0.9-0.6-1.2s-0.8-0.4-1.4-0.4
                c-0.6,0-1,0.1-1.3,0.3s-0.5,0.5-0.5,0.9c0,0.4,0.2,0.7,0.6,0.9c0.4,0.2,1,0.4,1.9,0.6c0.9,0.2,1.6,0.4,2.2,0.7
                c0.6,0.2,1.1,0.6,1.5,1.1s0.6,1.1,0.6,1.9c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1-1.7,1.3c-0.7,0.3-1.6,0.5-2.5,0.5
                C139.9,23.2,139,23,138.2,22.7z"
      />
      <path
        d="M159.5,17.8h-8.9c0.1,0.9,0.4,1.6,0.9,2.1c0.5,0.5,1.2,0.7,2,0.7c1.1,0,2-0.5,2.4-1.5h3.3
                c-0.4,1.2-1,2.1-2,2.9c-1,0.8-2.2,1.1-3.7,1.1c-1.2,0-2.2-0.3-3.2-0.8s-1.7-1.3-2.2-2.2s-0.8-2.1-0.8-3.3c0-1.3,0.3-2.4,0.8-3.3
                c0.5-1,1.2-1.7,2.2-2.2c0.9-0.5,2-0.8,3.2-0.8c1.2,0,2.2,0.2,3.1,0.7c0.9,0.5,1.6,1.2,2.1,2.1c0.5,0.9,0.8,2,0.8,3.2
                C159.6,17.1,159.5,17.5,159.5,17.8z M156.4,15.8c0-0.8-0.3-1.4-0.9-1.9s-1.2-0.7-2-0.7c-0.8,0-1.4,0.2-1.9,0.7
                c-0.5,0.5-0.8,1.1-1,1.9H156.4z"
      />
    </g>
    <g id="Group_30783" transform="translate(0 1.081)" fill="#27aabe">
      <g id="Group_4" transform="translate(0 0)">
        <g id="Group_1" transform="translate(5.315 5.135)">
          <g id="Path_1">
            <path
              d="M47.1,28.3V6.8c0-3.8-3.1-6.8-6.8-6.8H6.8C3.1,0,0,3.1,0,6.8v18.5c0,3.8,3.1,6.8,6.8,6.8h25.9l9.1,7.3
                            c1.4,1.1,3.5,0.9,4.6-0.5c0.5-0.6,0.7-1.3,0.7-2.1L47.1,28.3z M38.1,32.1l-4.2-3.4H6.8c-1.9,0-3.5-1.5-3.5-3.5V6.8
                            c0-1.9,1.6-3.5,3.5-3.5h33.5c1.9,0,3.5,1.6,3.5,3.5v21.5l0,3.8l0,4.6L38.1,32.1z"
            />
          </g>
        </g>
        <g id="Group_2" transform="translate(39.139 33.835)">
          <g id="Path_2">
            <path d="M0.1,0L0.1,0L0.1,0L0.1,0z" />
          </g>
        </g>
        <g id="Ellipse_1">
          <ellipse cx="17.3" cy="20.3" rx="2.8" ry="2.8" />
        </g>
        <g id="Ellipse_2">
          <ellipse cx="40.1" cy="20.3" rx="2.8" ry="2.8" />
        </g>
        <g id="Group_3" transform="translate(19.532 0)">
          <g id="Path_3">
            <path
              d="M16.7,8.5H0v-5C0,1.6,1.6,0,3.5,0h9.8c1.9,0,3.5,1.6,3.5,3.5L16.7,8.5z M3.4,5.1h10V3.5
                            c0-0.1-0.1-0.1-0.1-0.1H3.5c-0.1,0-0.1,0.1-0.1,0.1V5.1z"
            />
          </g>
        </g>
        <g id="Rectangle_1">
          <path
            d="M1.7,15.6L1.7,15.6c0.9,0,1.7,0.8,1.7,1.7v7.6c0,0.9-0.8,1.7-1.7,1.7h0c-0.9,0-1.7-0.8-1.7-1.7v-7.6
                        C0,16.4,0.8,15.6,1.7,15.6z"
          />
        </g>
        <g id="Rectangle_2">
          <path
            d="M55.9,15.6L55.9,15.6c0.9,0,1.7,0.8,1.7,1.7v7.6c0,0.9-0.8,1.7-1.7,1.7h0c-0.9,0-1.7-0.8-1.7-1.7v-7.6
                        C54.2,16.4,55,15.6,55.9,15.6z"
          />
        </g>
      </g>
    </g>
    <g>
      <path
        d="M77.7,48.1l-2.6-4.4h-1.9v4.4h-1V37.6h3.1c1.2,0,2.1,0.3,2.7,0.8c0.6,0.6,0.9,1.3,0.9,2.2
                c0,0.8-0.2,1.4-0.7,1.9c-0.4,0.5-1.1,0.9-1.9,1l2.7,4.5H77.7z M73.2,42.8h2.1c0.8,0,1.4-0.2,1.9-0.6c0.4-0.4,0.6-0.9,0.6-1.6
                c0-0.7-0.2-1.2-0.6-1.6c-0.4-0.4-1-0.6-1.9-0.6h-2.1V42.8z"
      />
      <path
        d="M88.3,44.4h-6.8c0,0.6,0.2,1.2,0.4,1.6c0.3,0.4,0.6,0.8,1.1,1c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.7-0.5
                s0.8-0.8,0.9-1.3h1.1c-0.2,0.8-0.6,1.5-1.3,2s-1.5,0.8-2.5,0.8c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.5
                c-0.3-0.6-0.5-1.4-0.5-2.2c0-0.9,0.2-1.6,0.5-2.2c0.3-0.6,0.8-1.1,1.4-1.5c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.5,0.2,2,0.5
                s1,0.8,1.4,1.4c0.3,0.6,0.5,1.2,0.5,1.9C88.4,43.9,88.4,44.2,88.3,44.4z M86.9,42c-0.3-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.3-1.4-0.3
                c-0.8,0-1.4,0.2-2,0.8c-0.6,0.5-0.9,1.2-0.9,2.1h5.8C87.3,42.9,87.2,42.4,86.9,42z"
      />
      <path
        d="M90.3,41.7c0.3-0.6,0.8-1.1,1.4-1.5c0.6-0.3,1.3-0.5,2.1-0.5c1,0,1.8,0.2,2.5,0.8c0.7,0.5,1.1,1.2,1.3,2h-1.1
                c-0.1-0.6-0.4-1.1-0.9-1.4c-0.5-0.3-1-0.5-1.7-0.5c-0.5,0-1,0.1-1.5,0.4c-0.4,0.2-0.8,0.6-1,1.1c-0.3,0.5-0.4,1.1-0.4,1.8
                c0,0.7,0.1,1.3,0.4,1.8s0.6,0.9,1,1.1c0.4,0.2,0.9,0.4,1.5,0.4c0.7,0,1.3-0.2,1.7-0.5c0.5-0.3,0.8-0.8,0.9-1.4h1.1
                c-0.2,0.8-0.6,1.5-1.3,2c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.5s-0.5-1.4-0.5-2.2
                C89.8,43.1,90,42.4,90.3,41.7z"
      />
      <path
        d="M101.6,40.1c0.5-0.3,1.1-0.4,1.7-0.4v1.1H103c-0.8,0-1.4,0.2-1.8,0.6s-0.7,1.1-0.7,2.1v4.6h-1v-8.2h1v1.5
                C100.8,40.8,101.1,40.4,101.6,40.1z"
      />
      <path
        d="M112,39.9v8.2h-1v-1.4c-0.2,0.5-0.6,0.9-1.1,1.2c-0.5,0.3-1.1,0.4-1.7,0.4c-1,0-1.8-0.3-2.4-0.9
                c-0.6-0.6-0.9-1.5-0.9-2.6v-4.8h1v4.7c0,0.9,0.2,1.6,0.7,2c0.4,0.5,1.1,0.7,1.8,0.7c0.8,0,1.4-0.2,1.9-0.8c0.5-0.5,0.7-1.2,0.7-2.2
                v-4.5H112z"
      />
      <path
        d="M114.4,38.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2
                c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.5,0.2S114.5,38.2,114.4,38.1z M115.5,39.9v8.2h-1v-8.2H115.5z"
      />
      <path
        d="M119.2,40.8v5.1c0,0.5,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,1,0.3h1v0.9h-1.1c-0.7,0-1.3-0.2-1.6-0.5
                c-0.4-0.3-0.5-0.9-0.5-1.7v-5.1H117v-0.9h1.1v-2.1h1.1v2.1h2.2v0.9H119.2z"
      />
      <path
        d="M130.6,44.4h-6.8c0,0.6,0.2,1.2,0.4,1.6c0.3,0.4,0.6,0.8,1.1,1c0.4,0.2,0.9,0.3,1.4,0.3c0.7,0,1.3-0.2,1.7-0.5
                s0.8-0.8,0.9-1.3h1.1c-0.2,0.8-0.6,1.5-1.3,2s-1.5,0.8-2.5,0.8c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.5s-0.5-1.4-0.5-2.2
                c0-0.9,0.2-1.6,0.5-2.2c0.3-0.6,0.8-1.1,1.4-1.5c0.6-0.3,1.3-0.5,2.1-0.5c0.8,0,1.5,0.2,2,0.5s1,0.8,1.4,1.4
                c0.3,0.6,0.5,1.2,0.5,1.9C130.6,43.9,130.6,44.2,130.6,44.4z M129.2,42c-0.3-0.4-0.6-0.8-1-1c-0.4-0.2-0.9-0.3-1.4-0.3
                c-0.8,0-1.4,0.2-2,0.8c-0.6,0.5-0.9,1.2-0.9,2.1h5.8C129.5,42.9,129.4,42.4,129.2,42z"
      />
      <path
        d="M134.6,40.1c0.5-0.3,1.1-0.4,1.7-0.4v1.1h-0.3c-0.8,0-1.4,0.2-1.8,0.6s-0.7,1.1-0.7,2.1v4.6h-1v-8.2h1v1.5
                C133.8,40.8,134.1,40.4,134.6,40.1z"
      />
      <path d="M143.1,38.4v3.9h4v0.9h-4v4h4.4v0.9H142V37.6h5.5v0.9H143.1z" />
      <path d="M153.9,48.1l-2.1-3.3l-2,3.3h-1.1l2.6-4.1l-2.6-4.1h1.2l2.1,3.3l2-3.3h1.1l-2.6,4.1l2.6,4.1H153.9z" />
    </g>
  </svg>
);

FooterFbIcon.defaultProps = {
  width: "160",
  height: "53.1",
};

export default FooterFbIcon;
