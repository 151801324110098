import React from "react";
import Header from "../Header/index";
import Footer from "../Footer/index";
import "../../style/main.scss";

const LayoutComponent = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <div className="content">{children}</div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default LayoutComponent;
