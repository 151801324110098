import * as React from "react";

const NextArrowIcon = ({ width, height, props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    <g fill="#27aabe">
      <path data-name="Rectangle 6409" d="M0 4.414h12.581v1.379H0z" />
      <path
        data-name="Path 23083"
        d="m9.247 0-.979.97 4.221 4.256-4.214 4.081.959.99 5.213-5.051Z"
      />
    </g>
  </svg>
);

NextArrowIcon.defaultProps = {
  width: "14.449",
  height: "10.297",
};

export default NextArrowIcon;
