import * as React from "react"

const FosterLogo = ({ width, height, props }: any) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 96 31"
        style={{
            enableBackground: "new 0 0 96 31",
        }}
        xmlSpace="preserve"
        {...props}
        width={width}
        height={height}
    >
        <style>{".st0{enable-background:new}.st1{fill:#111334}"}</style>
        <g id="Group_32306" transform="translate(-325 -267)">
            <g className="foster_1">
                <path
                    className="foster_2"
                    d="M363.5 291v-14.3h8.5l-.1 2.1h-5.7v4.1h5.5v2.1h-5.5v6.1h-2.7zM378.5 291.2c-1.6 0-2.8-.5-3.8-1.5-.9-1-1.4-2.4-1.4-4.1 0-1.7.5-3.1 1.4-4.1.9-1 2.2-1.5 3.9-1.5 1.6 0 2.9.5 3.8 1.5s1.4 2.4 1.4 4.1c0 1.7-.5 3.1-1.4 4.1s-2.3 1.5-3.9 1.5zm.1-1.9c.9 0 1.5-.3 2-.9s.7-1.5.7-2.7c0-1.2-.2-2.1-.7-2.7s-1.1-.9-2-.9c-.9 0-1.5.3-2 .9s-.7 1.5-.7 2.7c0 1.2.2 2.1.7 2.7s1.1.9 2 .9zM389.2 291.2c-1.6 0-2.8-.3-3.8-.8l.3-1.9c.4.2 1 .4 1.6.6s1.3.3 1.8.3c.6 0 1-.1 1.3-.3s.5-.5.5-.9-.1-.7-.4-.9-.9-.5-1.7-.8c-.3-.1-.5-.2-.6-.2-1-.4-1.7-.8-2.1-1.3s-.7-1.1-.7-1.9c0-1 .4-1.7 1.1-2.2s1.7-.8 3-.8c1.4 0 2.6.3 3.6.8l-.6 1.8c-1-.5-2-.7-2.9-.7-.5 0-.9.1-1.2.3s-.4.4-.4.8c0 .3.1.6.4.8s.8.4 1.6.7c0 0 .1 0 .3.1s.3.1.3.1c1 .4 1.7.8 2.2 1.3s.7 1.1.7 1.9c0 1.1-.4 1.9-1.1 2.5s-1.8.7-3.2.7zM399.7 291.1c-.7 0-1.3-.1-1.8-.3s-.9-.4-1.1-.8-.5-.8-.6-1.3-.2-1.1-.2-1.9v-5.1h-1.8l.1-1.4 1.8-.2.8-2.9h1.6v2.9h2.7v1.6h-2.7v5.1c0 .8.1 1.4.4 1.7s.7.5 1.3.5c.4 0 .8 0 1.2-.1l.1 1.8h-.2c-.1 0-.3.1-.4.1s-.3 0-.5.1-.5.2-.7.2zM408.1 291.2c-1.7 0-3.1-.5-4-1.5s-1.4-2.4-1.4-4.1c0-1.7.5-3.1 1.4-4.1s2.1-1.5 3.7-1.5c1.5 0 2.6.5 3.4 1.4s1.2 2.2 1.2 3.7v1h-7.1c0 1 .3 1.8.8 2.3s1.2.8 2.2.8c1.2 0 2.3-.3 3.4-.9l.4 1.8c-1.2.8-2.5 1.1-4 1.1zm-2.8-6.7h4.7c0-.9-.2-1.5-.6-2s-.9-.7-1.7-.7c-.7 0-1.2.2-1.7.7s-.6 1.1-.7 2zM414.5 291v-10.7h2.1l.3 1.6c.7-1.1 1.7-1.7 3.1-1.7h.7v2.1c-.3 0-.5-.1-.8-.1-.9 0-1.6.3-2.1.8s-.7 1.2-.7 2.1v5.8h-2.6z"
                />
            </g>
            <path
                d="M331 267h19c3.3 0 6 2.7 6 6v19c0 3.3-2.7 6-6 6h-19c-3.3 0-6-2.7-6-6v-19c0-3.3 2.7-6 6-6z"
                style={{
                    fill: "#fd2e35",
                }}
                id="Rectangle_6461"
            />
            <path
                d="M344.7 279.7h-4.4v3.1h4.1v2.3h-4.1v4.9h-2.8v-12.6h7.3v2.3z"
                style={{
                    fill: "#fff",
                }}
                className="foster_1"
            />
        </g>
    </svg>
)

FosterLogo.defaultProps = {
    width: '106.743',
    height: '34.974'
}

export default FosterLogo
