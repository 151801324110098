import * as React from "react"

const CloseIcon = ({width, height, props}:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width} height={height}
    {...props}
  >
    <g fill="#111334">
      <path
        data-name="Path 24202"
        d="M.139 21.753A2.811 2.811 0 0 1 0 20.981a2.285 2.285 0 0 1 .287-.729 1.037 1.037 0 0 1 .22-.238Q10.245 10.272 19.986.537a2.167 2.167 0 0 1 .508-.4 1.247 1.247 0 0 1 1.556.359 1.27 1.27 0 0 1-.064 1.618c-.086.1-.179.186-.27.277q-9.654 9.655-19.3 19.317a1.524 1.524 0 0 1-1.853.467l-.422-.422"
      />
      <path
        data-name="Path 24203"
        d="M.529.187a1.43 1.43 0 0 1 1.835.38q9.733 9.76 19.488 19.5a1.268 1.268 0 1 1-1.587 1.977 4.781 4.781 0 0 1-.367-.346Q10.256 12.057.606 2.421A1.516 1.516 0 0 1 .148.566Z"
      />
    </g>
  </svg>
)

CloseIcon.defaultProps = {
    width: '22.326',
    height: '22.325'
}

export default CloseIcon
